import { collection, deleteDoc, query, getDocs, doc, addDoc, getDoc, setDoc, updateDoc, runTransaction, where, serverTimestamp, Query } from 'firebase/firestore';
import { db } from '../firebase';
import { placeConverter } from '../models/placeModel'
import { result } from '../utils/result';
import { apiChatBot } from '../services/api_gate';
import { poolFacilidades, statusPlace } from '../constants/places';

async function clearBCMemory(idcampanha) {
  try {
    const api = await apiChatBot.post(`/clearbcm/${idcampanha}`)
    if (api.status === 200) {
      result.sucess = true
      result.msg = 'Memória da base de conhecimento limpa com sucesso.'
      result.data = api.data
    }
    else {
      result.sucess = false
      result.msg = 'Falha na limpeza da memória da base de conhecimento.'
      result.data = api.data
    }
    return result
  }
  catch (e) {
    result.sucess = false
    result.msg = 'Falha na limpeza da memória da base de conhecimento.'
    result.data = e
    return result
  }
}

export async function saveBaseConhecimento(user, campanha) {
  // Salvar a base de conhecimento no banco de dados firestore 
  // if (verifyToken(user)) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em saveBaseConhecimento', user, campanha)
    try {
      const campanhaDocRef = doc(db, 'contas/' + user.conta + '/knowledge/' + campanha.id)
      await setDoc(campanhaDocRef, { know: campanha.know })
      result.sucess = true
      result.msg = 'Base de conhecimento salva com sucesso.'
      result.data = campanha
      // retira a base de conhecimento anterior da memória
      const res = await clearBCMemory(campanha.id)
      if (res.sucess) {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Memória da base de conhecimento limpa com sucesso.')
      }
    }
    catch (e) {
      result.sucess = false
      result.msg = 'Falha na gravação da base de conhecimento.'
      result.data = e
    }
    finally {
      return result
    }
  // } else {
  //   result.sucess = false
  //   result.msg = 'Usuário sem privilégio para a operação...'
  //   result.data = null
  //   return result
  // }
}

export async function createBaseConhecimento(user, places) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em createBaseConhecimento', user, places)

  const result = await gerarBasedeConhecimento(user.conta, places)

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de createBaseConhecimento', result)
  if (result.success) {
    result.msg = 'Base de conhecimento criada com sucesso.'
    return result
  } else {
    result.msg = 'Falha na criação da base de conhecimento.'
    return result
  }
}

function createKnowledegBase(places) {
  const knowledgeBase = [];
  places.forEach((place) => {
    let p_caracteristicas = ''
    if (place.empreendimento) { p_caracteristicas += place.empreendimento + '. '; }
    if (place.construtora) { p_caracteristicas += `Construtora:  ${place.construtora}. `; }
    if (place.entrega) { p_caracteristicas += `Com entrega para ${place.entrega}. `; }
    if (place.blocos) { p_caracteristicas += `Tem ${place.blocos} torres. `; }
    if (place.andares) { p_caracteristicas += `Tem ${place.andares} andares. `; }
    if (place.maxarea) { p_caracteristicas += `O maior imóvel com ${place.maxarea} m² de área. `; }
    if (place.minarea) { p_caracteristicas += `O menor imóvel com ${place.minarea} m² de área. `; }
    if (place.quartosqtdmax && place.quartosqtdmin && (place.quartosqtdmax !== place.quartosqtdmin)) { p_caracteristicas += `Tem de ${place.quartosqtdmin} até ${place.quartosqtdmax} quartos. `; }
    if (place.quartosqtdmax && place.quartosqtdmin && (place.quartosqtdmax === place.quartosqtdmin)) { p_caracteristicas += `Tem de ${place.quartosqtdmin} quartos. `; }
    if (place.quartosqtdmax && !place.quartosqtdmin) { p_caracteristicas += `Tem de ${place.quartosqtdmax} quartos. `; }
    if (!place.quartosqtdmax && place.quartosqtdmin) { p_caracteristicas += `Tem de ${place.quartosqtdmin} quartos. `; }
    if (place.vlrinicial) { p_caracteristicas += `Valor inicial: R$ ${place.vlrinicial}. `; }
    if (place.vlrfinal) { p_caracteristicas += `Valor final: R$ ${place.vlrfinal}. `; }
    if (place.refvlrmes && place.refvlrano) { p_caracteristicas += `O mês/ano de referência dos valores é ${place.refvlrmes}/${place.refvlrano}. `; }
    if (place.endereco) { p_caracteristicas += `Endereço: ${place.endereco}. `; }
    if (place.descricao) { p_caracteristicas += `Outras informações: ${place.descricao}. `; }
    if (place.situacao) { p_caracteristicas += `Está em fase de ${statusPlace.Description[place.situacao]}. `; }
    let p_renda = ''
    if (place.renda) { p_renda += `Renda familiar estimada: R$ ${place.renda}. `; }
    let p_facilidades = ''
    poolFacilidades.forEach((item) => {
      if (place[item.campo]) {
        p_facilidades += `Tem ${item.nome}. `;
      }
    });
    let p_divulgacao = ''
    // if (place.divulgacao) { p_divulgacao += `Material de divulgação no link ${place.divulgacao}. `; }
    if (place.urlimagem) { p_divulgacao += `Imagem do empreendimento no link ${place.urlimagem}. `; }
    let prompt = '{'
    if (p_caracteristicas) { prompt += 'Empreendimento: ' + p_caracteristicas; }
    if (p_renda) { prompt += 'Renda: ' + p_renda; }
    if (p_facilidades) { prompt += 'Facilidades: ' + p_facilidades; }
    if (p_divulgacao) { prompt += 'Divulgação: ' + p_divulgacao; }
    prompt += '},';
    const knowledge = {
      prompt: prompt,
    };
    knowledgeBase.push(knowledge);
  }
  );
  return knowledgeBase;
}


async function gerarBasedeConhecimento(_conta, _places) {
  try {
    const conta = _conta;
    const filtro = _places;

    const places = [];
    const path = `contas/${conta}/places`;
    let placeQuery = null
    const placeColRef = collection(db, path)

    if (filtro.length === 0) {
      placeQuery = await getDocs(placeColRef);
    } else {
      const q = query(placeColRef, where('id', 'in', filtro));
      placeQuery = await getDocs(q);
    }
    placeQuery.forEach((doc) => {
      places.push(doc.data());
    });
    const know = createKnowledegBase(places).map((item) => item.prompt).join('');
    return { success: true, data: know.slice(0, know.length - 1) }
  } catch (error) {
    // Retorne o erro em formato JSON
    return { success: false, message: error.message };
  }
}



export async function deletePlace(place, user) {
  // if (verifyToken(user)) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em DeletePlace')
    try {
      const placeDocRef = doc(db, 'contas/' + user.conta + '/places/' + place.id)
      await deleteDoc(placeDocRef)
      result.sucess = true
      result.msg = 'Registro excluído com sucesso.'
      result.data = null
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de deletePlace')
    }
    catch (e) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro em deletePlace', e)
      result.sucess = false
      result.msg = 'Falha na exclução do registro.'
      result.data = e
    }
    finally {
      return result
    }
  // }
  // else {
  //   result.sucess = false
  //   result.msg = 'Usuário sem privilégio para a operação...'
  //   result.data = null
  //   return result
  // }
}

// export async function loadListExample(user) {
//   if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em loadListExample', user)
//   // if (verifyToken(user)) {
//     let list = []
//     try {
//       result.sucess = true
//       result.msg = 'Imóveis carregados com sucesso'
//       const placeRef = doc(db, 'contas/8ryG2pTmV3dq9yoz1QVs/places', '4MuW5XR4FAHuCCDPeipO').withConverter(placeConverter)
//       const docSnap = await getDoc(placeRef);
//       if (docSnap.exists) {
//         list.push(docSnap.data());
//       }
//       result.data = list
//     }
//     catch (e) {
//       result.sucess = false
//       result.msg = 'Falha na carga dos imóveis'
//       result.data = e
//       if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha em loadListExample', e)
//     }
//     finally {
//       if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de loadListExample', result)
//       return result
//     }
//   // } else verifyToken(user)
// }

export async function listAllPlaces(user) {
  // if (verifyToken(user)) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em carregar places:', user)
    let list = []
    const placeColRef = collection(db, 'contas/' + user.conta + "/places")
    // .withConverter(placeConverter)
    const querySnapshot = await getDocs(placeColRef);
    querySnapshot.forEach((doc) => {
      const place = doc.data()
      place.id = doc.id
      list.push(place);
    });
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu em carregar places:', list)
    return list
  // } else verifyToken(user)
}
// Função auxiliar para remover atributos nulos
const removeNullAttributes = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([_, v]) => v != null));
};

export async function savePlace(place, user) {
  // if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em savePlace', place, user)
  if (place.position === undefined) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em falta de position', place.position)
    result.sucess = false
    result.msg = '- Falha por falta de latlng'
    result.data = place
    return result
  }
  let msg = '';
  function validar() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Objeto de entrada na validação:', place)
    let e = 0;
    if (place.empreendimento === undefined || place.empreendimento === '') { e++; msg += '- Nome' }
    if (place.construtora === undefined || place.construtora === '') { e++; msg += '- Construtora' }
    if (place.endereco === undefined || place.endereco === '') { e++; msg += '- Endereço' }
    if (e > 0) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha na validação')
      result.sucess = false
      result.msg = msg
      result.data = place
      return false
    }
    else {
      return true
    }
  }

  if (validar()) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Gravação', place, user)
    try {
      let placeDocRef = undefined
      let docRef = undefined
      if (place.id === undefined) {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Insert')
        place.idcad = user.id
        place.createdat = serverTimestamp()
        placeDocRef = collection(db, 'contas/' + user.conta + "/places/")
        const cleanedPlace = removeNullAttributes(place)
        docRef = await addDoc(placeDocRef, cleanedPlace)
        place.id = docRef.id
      }
      else {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Update')
        placeDocRef = doc(db, 'contas/' + user.conta + "/places/" + place.id)
        const cleanedPlace = removeNullAttributes(place)
        docRef = await setDoc(placeDocRef, cleanedPlace, { merge: true })
      }
      result.sucess = true
      result.msg = 'Registro gravado com sucesso.'
      result.data = place.id
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Saiu de savePlace', result)
    }
    catch (e) {
      result.sucess = false
      result.msg = 'Falha na gravação do registro.'
      result.data = e
    }
    finally {
      return result
    }
  }
  else {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em Saida de Erro por Validação')
    result.sucess = false
    return result
  }
}