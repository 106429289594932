import { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import {
  useTheme, Card, CardContent, Alert, AlertTitle,
  Select, MenuItem, Divider, FormHelperText, FormControl,
  FormLabel, CircularProgress
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { Copyright } from '../Copyright';
import Checkbox from '@mui/material/Checkbox';
import { ModalApp } from '../../components/Modal/ModalApp';
import { serverTimestamp } from 'firebase/firestore';
import { TextFieldMask } from '../../utils/TextfieldMask/TextfieldMask';
import { userModel } from '../../models/userModel';
import { userCreateNew } from '../../controllers/userSingUp';
import { UF } from '../../utils/dados/DadosUF';
import { rules } from '../../constants/rules';


export default function SingUp() {

  const [searchParams, setSearchParams] = useSearchParams()
  const [isLoading, setIsLoading] = useState(false)

  const nome = searchParams.get('nome')
  const email = searchParams.get('email')

  const theme = useTheme();

  const navigate = useNavigate()

  const [isError, setIsError] = useState(null)
  const [account, setAccount] = useState(null)
  const [licenca, setLicenca] = useState(null)

  const [ufcreci, setUFCreci] = useState('0')
  const handleUFCRECI = (event) => {
    setUFCreci(event.target.value);
  }

  // async function getLicencaByEmail(email) {
  //   setLoading(true)
  //   try {
  //     const api = await (await apiGate.get('/clients/licenca/' + email)).data
  //     if (api) {
  //       setLicenca(api)
  //     }
  //   }
  //   catch (e) {
  //     return null
  //   }
  //   finally {
  //     setLoading(false)
  //   }
  // }

  useEffect(() => {
    if (account) {
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em useEffect de desviou para Home')
      // navigate('/home', { state: { account: account } })
      navigate('/singin', { replace: true })
    }
  }, [account, navigate])

  function goToSingIn() {
    navigate('/singin', { replace: true })
  }

  function goToForget() {
    navigate('/forget', { replace: true })
  }

  async function addNewUser(pws, user, team) {

    // Cadastra o novo usuario
    const rst = await userCreateNew(pws, user, team)
    // Verificar necessidade de instanciar Account devido ao fato de encaminhar 
    // o novo usuário para o Login e não para a Home
    if (rst.sucess) {
      setIsError(null)
      setAccount({
        id: rst.data.id,
        email: rst.data.email,
        nome: rst.data.nome,
        permissions: rst.data.permissions,
        rules: rst.data.rules,
        conta: rst.data.conta,
        plano: rst.data.plano,
        photourl: rst.data.photoURL || '/assets/avatars/avatar1.jpg',
        token: rst.data.token,
        emailVerified: rst.data.emailVerified
      });
    }
    else {
      setIsError(rst.msg)
    }
    setLoading(false)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Retorno AddNewUser', rst)
  }

  const [vNome, setVNome] = useState(Boolean(false))
  const [vEmail, setVEmail] = useState(Boolean(false))
  const [vUFCreci, setVUFCreci] = useState(Boolean(false))
  const [vNrCreci, setVNrCreci] = useState(Boolean(false))
  const [vSenha, setVSenha] = useState(Boolean(false))
  const [vConvite, setVConvite] = useState(Boolean(false))
  const [vTpPFPJ, setVTpPFPJ] = useState(Boolean(false))

  const handleSubmit = async (event) => {

    event.preventDefault();

    const data = new FormData(event.currentTarget);

    const pws = data.get('password')
    const convite = data.get('convite')

    let userRules = team ? [rules.ruleViewer.title] : [rules.ruleAdm.title]
    let userPermissions = team ? rules.ruleViewer.permissions : rules.ruleAdm.permissions

    const user = new userModel(
      null,
      null,
      data.get('nome'),
      data.get('email').toLocaleLowerCase(),
      data.get('photourl'),
      tppfpj,
      data.get('ufcreci'),
      data.get('nrcreci'),
      data.get('convite'),
      userPermissions,
      userRules,
      'FREE',
      true,
      serverTimestamp(), '', '', false, false, '', '', '', '', '', '', '', serverTimestamp(), 0
    )
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Usuario', user)

    function valid() {
      let i = 0
      let msg = 'Informar: '
      if (user.nome.length < 3) {
        i++
        setVNome(true)
        msg += '- Nome '
      } else (setVNome(false))

      if (user.email.length < 7) {
        i++
        setVEmail(true)
        msg += '- Email '
      } else (setVEmail(false))

      if (user.ufcreci.length < 2) {
        i++
        setVUFCreci(true)
        msg += '- UF CRECI '
      } else (setVUFCreci(false))

      if (user.nrcreci.length < 5) {
        i++
        setVNrCreci(true)
        msg += '- Nr CRECI '
      } else (setVNrCreci(false))

      if (user.tppfpj.length < 2) {
        i++
        setVTpPFPJ(true)
        msg += '- P.F ou P.J'
      } else (setVTpPFPJ(false))

      if (pws.length < 8) {
        i++
        setVSenha(true)
        msg += '- Senha '
      } else (setVSenha(false))

      if (team) {
        if (user.convite.length < 3) {
          i++
          setVConvite(true)
          msg += '- Código do Convite'
        } else (setVConvite(false))
      }

      if (i > 0) {
        return false
      } else {
        setIsError(null)
        return true
      }
    }

    if (valid()) {
      setLoading(true)
      // Adiciona novo usuário
      await addNewUser(pws, user, team)
    }
  }

  const [team, setTeam] = useState(false)
  const handleEquipe = (event) => {
    if (event.target.checked) { setTeam(true) }
    else setTeam(false)
  }

  const [agree, setAgree] = useState(false)
  const handleAgree = (event) => {
    if (event.target.checked) { setAgree(true) }
    else setAgree(false)
  }

  const [openModal, setOpenModal] = useState(false)
  const [tpModal, setTpModal] = useState('T')
  const openModalView = (open, tp) => {
    setOpenModal(open)
    setTpModal(tp)
  }
  const [tppfpj, setPFPJ] = useState('N')

  const handlePFPJ = (event) => {
    setPFPJ(event.target.value);
  }


  const lbPolitica = (
    <Typography variant='body2' fontSize={'12px'}>Eu li e concordei com os&nbsp;
      <Button onClick={() => { openModalView(true, 'T') }}>
        <Typography variant='body2' fontSize={'10px'} sx={{ textDecoration: 'underline' }}>
          Termos de Uso
        </Typography>
      </Button>
      &nbsp; e com a&nbsp;
      <Button onClick={() => { openModalView(true, 'P') }}>
        <Typography variant='body2' fontSize={'10px'} sx={{ textDecoration: 'underline' }}>
          Política de Privacidade
        </Typography>
      </Button>
    </Typography>
  )
  const [loading, setLoading] = useState(false)

  return (

    <Grid container component="main" sx={{
      height: '100vh',
    }}>

      <ModalApp tpModal={tpModal} openModal={openModal} setOpenModal={setOpenModal} height={'70vh'} width={'70vw'} />
      <Grid item
        xs={12}
        md={7}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="./gimov.svg" alt="Logo" style={{ width: '80%', height: 'auto' }} />
      </Grid>
      <Grid item
        xl={4}
        lg={4}
        md={12}
        sm={12}
        container
        alignContent='center'
      >
        <Grid
          item
          container
          justifyContent='center'
          overflow={'auto'}
        >
          <Card sx={{
            width: '75%',
            [theme.breakpoints.down('md')]: {
              width: '90%',
            }
          }}>
            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                {/* <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <PersonAddIcon />
                </Avatar> */}
                {/* <Typography component="div" variant="h4">
                  Seja Bem-vindo!
                </Typography> */}
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="nome"
                    label="Nome"
                    name="nome"
                    type='text'
                    autoFocus
                    error={vNome}
                    defaultValue={nome}
                    inputProps={{ maxLength: 70 }}
                  //  helperText={vNome ? "Dados incorretos." : ' '}
                  />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    defaultValue={email}
                    error={vEmail}
                    inputProps={{ maxLength: 80 }}
                  // helperText={vEmail ? "Dados incorretos." : ' '}
                  />
                  <Divider sx={{ my: '10px' }} />
                  <Typography mt='10px'>Conselho Regional de Corretores de Imóvel</Typography>
                  <Grid container alignItems='center' columnSpacing='10px' >

                    <Grid item mt='4px'>
                      <Select
                        labelId="idlabelUFCRECI"
                        id="ufcreci"
                        name='ufcreci'
                        value={ufcreci}
                        onChange={handleUFCRECI}
                        width='200px'
                        required
                        error={vUFCreci}
                      >
                        <MenuItem key={'0'} value='0'><em>UF</em></MenuItem>
                        {UF.map((_uf) =>
                          <MenuItem key={_uf.sigla} value={_uf.sigla}>{_uf.sigla}</MenuItem>
                        )}
                      </Select>
                    </Grid>
                    <Grid item>
                      <TextFieldMask
                        required
                        type="text"
                        id="nrcreci"
                        name="nrcreci"
                        margin="normal"
                        label="Nr CRECI"
                        sx={{ width: '110px' }}
                        error={vNrCreci}
                        mask={'nrcreci'}
                      />
                    </Grid>
                    <Grid item mt='4px'>
                      <Select
                        id="tppfpj"
                        name='tppfpj'
                        value={tppfpj}
                        onChange={handlePFPJ}
                        required
                        error={vTpPFPJ}
                      >
                        <MenuItem key={'N'} value='N'><em>P.F ou P.J</em></MenuItem>
                        <MenuItem key={'pf'} value='pf'>P.Física</MenuItem>
                        <MenuItem key={'pj'} value='pj'>P.Jurídica</MenuItem>
                      </Select>
                    </Grid>
                  </Grid>
                  <Divider sx={{ my: '10px' }} />
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Senha"
                    type="password"
                    id="password"
                    autoComplete="current-password"
                    error={vSenha}
                    helperText={">=8 lts, 1 lt Maiúscula"}
                    inputProps={{ maxLength: 10 }}
                  />
                  <Divider sx={{ my: '10px' }} />

                  <FormControl>
                    <FormLabel id="rgLabel">Equipe</FormLabel>
                    <FormControlLabel
                      id='cbEquipe'
                      name='cbEquipe'
                      control={<Checkbox />}
                      onChange={handleEquipe}
                      label="Entrar com convite da equipe" />
                  </FormControl>
                  <TextFieldMask
                    margin="normal"
                    fullWidth
                    name="convite"
                    label="Código do convite"
                    type="text"
                    id="convite"
                    disabled={!team}
                    aria-describedby="equipe-ajuda"
                    error={vConvite}
                    mask={'convite'}>
                  </TextFieldMask>

                  <FormHelperText id="equipe-ajuda">
                    * Solicite o código para o gestor da equipe
                  </FormHelperText>
                  <Divider sx={{ my: '10px' }} />

                  <FormControlLabel
                    id='cbTermo'
                    name='cbTermo'
                    control={<Checkbox />}
                    required
                    label={lbPolitica}
                    onChange={handleAgree}
                  />
                  <Box sx={{ m: 1, position: 'relative' }}>
                    {isError && (
                      <Grid sx={{ mt: '10px', width: '100%' }}>
                        <Typography component={'span'} variant='body1'>
                          <Alert severity="error">
                            <AlertTitle>Falha na autenticação</AlertTitle>
                            <strong>{isError}</strong>
                          </Alert>
                        </Typography>
                      </Grid>
                    )}
                    <Button
                      id='button'
                      name='buton'
                      type="submit"
                      fullWidth
                      variant="contained"
                      disabled={!agree}
                      sx={{ my: 2 }}
                    >
                      Enviar
                    </Button>
                    {loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: theme.palette.warning.dark,
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Box>
                  <Grid container>
                    <Grid item xs>
                      <Link onClick={goToForget}>Esqueceu a senha?</Link>
                    </Grid>
                    <Grid item>
                      <Link onClick={goToSingIn}>Já tem conta? Entre</Link>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 2 }} />

                </Box>

              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>

  );
}