import { CircularProgress, Button, FormControlLabel, Grid, Input, Typography } from '@mui/material';
import { createBaseConhecimento, listAllPlaces } from '../../../../controllers/placeController';
import { useEffect, useState } from 'react';
import Checkbox from '@mui/material/Checkbox';


export function SelectImoveis({ user, setKnowledge, places, setPlaces, setSnackbar, setChangeKnowledge }) {

  const [filter, setFilter] = useState('')
  const [placesFiltered, setPlacesFiltered] = useState([])
  const [cbTodos, setCbTodos] = useState(false)
  const [loading, setLoading] = useState(false)


  async function gerarBase() {
    try {

      setLoading(true)
      let placesSelected = []
      // if (cbTodos) {
      //   placesSelected = []
      // } else {
      placesSelected = places.filter(p => p.checked).map(p => p.id)
      if (placesSelected.length === 0) {
        setSnackbar({ open: true, message: 'Selecione ao menos um imóvel', severity: 'warning' })
        return
      }
      // }
      const result = await createBaseConhecimento(user, placesSelected)
      if (result.success) {
        setKnowledge(result.data)
        setChangeKnowledge(true)
        setSnackbar({ open: true, message: result.msg, severity: 'success' })
      }
    }
    catch (e) {
      setSnackbar({ open: true, message: 'Falha ao criar base de conhecimento', severity: 'error' })
    }
    finally {
      setLoading(false)
    }
  }

  async function loadImoveis() {
    if (places.length === 0) {
      setLoading(true)
      const result = await listAllPlaces(user)
      if (result) {
        result.map(place => place.checked = false)
        setPlaces(result)
        setPlacesFiltered(result)
      }
      setLoading(false)
    }
    else {
      setPlacesFiltered(places)
    }
  }

  function filterPlaces(event) {

    setFilter(event.target.value)
    event.preventDefault()
    const _filter = event.target.value

    if (_filter === '') {
      setPlacesFiltered(places)
    } else {
      const result = places.filter(place => place.empreendimento.toLowerCase().includes(_filter.toLowerCase()))
      setPlacesFiltered(result)
    }
  }

  function handleCheckAll(event) {
    const updatedPlaces = places.map(p => {
      return { ...p, checked: !cbTodos };
    });
    setPlaces(updatedPlaces);
    setPlacesFiltered(updatedPlaces.filter(p => p.empreendimento.toLowerCase().includes(filter.toLowerCase())));
    setCbTodos(!cbTodos)
  }

  function handleCheck(event, place) {
    const updatedPlaces = places.map(p => {
      if (p.id === place.id) {
        return { ...p, checked: !p.checked };
      }
      return p;
    });
    setPlaces(updatedPlaces);
    setPlacesFiltered(updatedPlaces.filter(p => p.empreendimento.toLowerCase().includes(filter.toLowerCase())));
  }



  useEffect(() => {
    loadImoveis()
  }, [])

  return (
    <Grid container
      direction={'column'}
      bgcolor={'#f5f5f5'}
    >

      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}

      <Grid container p={1} alignItems={'center'} justifyContent={'space-between'}>
        <Input sx={{ width: '80%' }} placeholder="Pesquisar imóveis" onChange={filterPlaces} />
        <FormControlLabel control={<Checkbox checked={cbTodos} onChange={(event) => handleCheckAll(event)} />} label="Todos" />
      </Grid>
      <Grid container
        width={'100%'}
        // height={'65vh'}
        maxHeight={'65vh'}
        overflow={'auto'}
        alignContent={'flex-start'}
        gap={1}
        p={1}
        sx={{ marginBottom: '10px' }}

      >
        {placesFiltered?.map((place, index) => (
          <Grid item
            key={index}
            bgcolor={'#fff'}
            borderRadius={2}
            mt={1}
            p={1}
            border={'1px solid #ccc'}
            width={'250px'}
          >
            <Grid container>
              <Grid item xs={10}>
                <Typography variant={'body1'}>{place.empreendimento}</Typography>
                <Typography variant={'body2'}>{place.endereco}</Typography>
              </Grid>
              <Grid item xs={2} alignContent={'flex-start'}>
                <Checkbox
                  checked={place.checked}
                  onChange={(event) => handleCheck(event, place)}
                />
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Button variant='contained' onClick={() => gerarBase()}>Criar Base de Conhecimento</Button>
    </Grid>
  );
}