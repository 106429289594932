// Função react para mostrar Dialog com informações do KnowledgeDetail

import { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import { Grid, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { SelectImoveis } from './SelectImoveis';

export default function KnowledgeDetail({ user, places, setPlaces, open, handleClose, setSnackbar, Knowledge, setKnowledge, setChangeKnowledge   }) {

  const [value, setValue] = useState('1');
  

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      fullScreen={true}
    >
      <DialogTitle id="alert-dialog-title">GIMO - Base de Conhecimento</DialogTitle>
      <DialogContent >
        <Grid container >
          <Box sx={{
            width: '100%',
            // height: '400px',
            typography: 'body1'
          }}
          >
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <TabList onChange={handleChange} aria-label="GIMO Knowledge Detail">
                  <Tab label="Imóveis" value="1" />
                  {/* <Tab label="B.Conhecimento" value="2" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                <SelectImoveis
                  user={user}
                  places={places}
                  setPlaces={setPlaces}
                  setKnowledge={setKnowledge}
                  setSnackbar={setSnackbar}
                  setChangeKnowledge={setChangeKnowledge}
                />
              </TabPanel>
              <TabPanel value="2">
                {Knowledge}
              </TabPanel>
            </TabContext>
          </Box>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary" autoFocus>
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}