export const statusPlace = Object.freeze({
  Lançamento: 'LA',
  EmObra: 'EO',
  EmObraEntregue: 'OE',
  Entregue: 'E',
  Vendido: 'V',
  Description: {
    LA: 'Lançamento',
    EO: 'Em Obra',
    OE: 'Em Obra/Entregue',
    E: 'Entregue',
    V: '100% Vendido',
  }
})

export const poolFacilidades = [
  { 'nome': 'Academia', 'campo': 'academia' },
  { 'nome': 'Beach Tênis', 'campo': 'beachtenis' },
  { 'nome': 'Bicicletário', 'campo': 'bicicletario' },
  { 'nome': 'Brinquedoteca', 'campo': 'brinqdteca' },
  { 'nome': 'Cinema', 'campo': 'cinema' },
  { 'nome': 'Coworking', 'campo': 'coworking' },
  { 'nome': 'Churrasqueira', 'campo': 'churrasqueira' },
  { 'nome': 'Elevador', 'campo': 'elevador' },
  { 'nome': 'Espaço kids', 'campo': 'espacokids' },
  { 'nome': 'Espaço gourmet', 'campo': 'gourmet' },
  { 'nome': 'Esporte bar', 'campo': 'esportebar' },
  { 'nome': 'Garden', 'campo': 'garden' },
  { 'nome': 'Lavanderia', 'campo': 'lavanderia' },
  { 'nome': 'Marketplace', 'campo': 'marketplace' },
  { 'nome': 'Piscina Adulto', 'campo': 'piscina' },
  { 'nome': 'Piscina Infantil', 'campo': 'piscinainf' },
  { 'nome': 'Port-cochère', 'campo': 'portcochere' },
  { 'nome': 'Playground', 'campo': 'playgroud' },
  { 'nome': 'Quiosque com churrasqueira', 'campo': 'quiosquechur' },
  { 'nome': 'Quadra esportiva', 'campo': 'qdesportiva' },
  { 'nome': 'Quadra de tênis', 'campo': 'qdtenis' },
  { 'nome': 'Quadra de vôlei', 'campo': 'qdvolei' },
  { 'nome': 'Pet care', 'campo': 'petcare' },
  { 'nome': 'Pet place', 'campo': 'petplace' },
  { 'nome': 'Rooftop', 'campo': 'rooftop' },
  { 'nome': 'Suíte', 'campo': 'suite' },
  { 'nome': 'Sauna', 'campo': 'sauna' },
  { 'nome': 'Sacada', 'campo': 'sacada' },
  { 'nome': 'Sala multiuso', 'campo': 'salamult' },
  { 'nome': 'Salão de festas', 'campo': 'salafesta' },
  { 'nome': 'Salão de jogos', 'campo': 'salajogos' },
  { 'nome': 'Vaga fixa', 'campo': 'vagafixa' },
  { 'nome': 'Vaga rotativa', 'campo': 'vagarotativa' },
]
