import { Button, CircularProgress, Grid, IconButton, Typography, useTheme } from '@mui/material';
import { leadStatus, leadDetailOptions } from '../../../constants/crm';
import { useState } from 'react';
import LeadTaskDetails from './LeadTaskDetails';
import ClinteDetalhe from '../Clientes/ClienteDetalhe';
import { LeadTaskSelect } from './LeadTaskSelect';
import { WhastForm } from '../Whatsapp/WhatsForm';
import dayjs from 'dayjs';

dayjs.locale('pt-br');

export default function LeadDetails({ account, lead, setShowMyLeads, setShowMenu, setShowLeadDetails,
  setShowOption, setOption, setSnackbar, setUpdate, update }) {

  const [filtro, setFiltro] = useState(null)
  const [loading, setLoading] = useState(false)

  const [showTasklList, setShowTaskList] = useState(true)
  const [taskList, setTaskList] = useState([])
  const [taskListFiltered, setTaskListFiltered] = useState([])

  const [task, setTask] = useState({})
  const [showTask, setShowTask] = useState(false)

  const [showIdentification, setShowIdentification] = useState(false)

  const [showWhatsapp, setShowWhatsapp] = useState(false)

  const theme = useTheme();

  // const Transition = forwardRef(function Transition(props, ref) {
  //   return <Slide direction="up" ref={ref} {...props} />;
  // });

  function handleSendWebWhatsapp(phone, nome, msg) {
    let _nrPhone = phone.replace(/\D/g, '');
    if (_nrPhone.startsWith('0')) {
      _nrPhone = _nrPhone.substring(1);
    }
    _nrPhone = `55${_nrPhone}`;
    if (msg === '') {
      msg = `Olá! Meu nome é ${nome} e sou corretor de imóveis. Agradeço pelo seu contato. Como posso auxiliá-lo hoje?`
    }
    const uri = `https://wa.me/${_nrPhone}?text=${msg}`
    const encoded = encodeURI(uri)
    const win = window.open(encoded, '_blank');
    win.focus();
  }

  function handleClose() {
    setShowLeadDetails(false)
    setShowMyLeads(true)
  }

  function handleOption(option) {
    setOption(option)
    setShowTaskList(false)
    setShowIdentification(false)
    setShowWhatsapp(false)
    setShowTaskList(false)

    if (option === leadDetailOptions.Task) {
      setShowTask(true)
    }
    if (option === leadDetailOptions.Whatsapp) {
      setShowWhatsapp(true)
    }
    if (option === leadDetailOptions.Identification) {
      setShowIdentification(true)
    }
    if (option === leadDetailOptions.Gimo) {
      // setShowGimo(true)
    }
    if (option === leadDetailOptions.TaskList) {
      setShowTaskList(true)
    }
    // setShowOption(true)
    // setShowLeadDetails(true)
  }
  // console.log('lead', lead)

  return (
    <Grid container >
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid
        container
        gap={1}
        justifyContent={'center'}
        height={'88vh'}
        alignContent={{ xs: 'flex-start', md: 'center' }}
        bgcolor={'#f5f5f5'}
        overflow={'auto'}
        pt={{ xs: 1, sm: 0 }}
      >
        <Grid container
          width={{
            xs: '100%', // largura máxima em telas pequenas
            md: '400px' // largura de 400px em telas grandes
          }}
          height={'60vh'}
          direction={'row'}
          p={1}
          bgcolor={'white'}
          border={'1px solid #ccc'}
          justifyContent="space-between"
        >
          <Grid item
            height={'52vh'}
            bgcolor={'#f5f5f5'}
            width={'100%'}
            p={1}
            overflow={'auto'}
          >
            <Typography component={'div'} variant="body1">Nome:</Typography>
            <Typography component={'div'} variant="body1"
              fontWeight={'fontWeightBold'}
              bgcolor={theme.palette.secondary.main}
              p={0.5}>{lead.nome} </Typography>
            <Typography component={'div'} variant="body1">Telefone:</Typography>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="body1"
                fontWeight={'fontWeightBold'}
                bgcolor={theme.palette.secondary.main}
                p={0.5} paddingRight={'10px'}>{lead.telefone} </Typography>
              <IconButton onClick={() => handleSendWebWhatsapp(lead.telefone, account.nome, '')} >
                <img src="/assets/images/WhatsAppButtonGreenSmall.png" alt="whatsapp" height={'32px'} />
              </IconButton>
            </div>
            {lead.email && (
              <>
                <Typography component={'div'} variant="body1">Email:</Typography>
                <Typography component={'div'} variant="body1"
                  fontWeight={'fontWeightBold'}
                  bgcolor={theme.palette.secondary.main}
                  p={0.5}>{lead.email}
                </Typography>
              </>
            )}

            <Typography component={'div'} variant="body1">Interesse:</Typography>
            <Typography component={'div'} variant="body1"
              fontWeight={'fontWeightBold'}
              // bgcolor={theme.palette.secondary.main} 
              p={0.5}>{lead.interesse} </Typography>
            <Typography component={'div'} variant="body1"  >Status: </Typography>
            <Typography component={'div'} variant="body1"
              fontWeight={'fontWeightBold'}
              // bgcolor={theme.palette.secondary.main} 
              p={0.5}>{leadStatus.Description[lead.status]} </Typography>
            <Typography component={'div'} variant="body1">Origem:</Typography>
            <Typography component={'div'} variant="body1"
              fontWeight={'fontWeightBold'}
              // bgcolor={theme.palette.secondary.main} 
              p={0.5}>{lead.origem} </Typography>
            <Typography component={'div'} variant="body1" fontSize={'12px'}>{`Desde: ${dayjs(lead.createdat).format('DD/MM/YYYY HH:mm:ss')}`} </Typography>
            <Typography component={'div'} variant="body1" fontSize={'12px'}>{`Atualizado: ${dayjs(lead.updatedat).format('DD/MM/YYYY HH:mm:ss')}`} </Typography>
            {/* </Grid> */}
          </Grid>
          <Grid container
            justifyContent={'space-between'}
            mt={1}
          >
            {!showIdentification && (<Button sx={{ height: '28px' }} variant='contained' size='small' onClick={() => handleOption(leadDetailOptions.Identification)}>Cliente</Button>)}
            {showIdentification && (<Button sx={{ height: '28px' }} variant='contained' size='small' onClick={() => handleOption(leadDetailOptions.TaskList)}>Atividades</Button>)}
            {/* <Button variant='contained' size='small' onClick={() => handleOption(leadDetailOptions.Gimo)}>GIMO</Button> */}
            {/* <Button variant='contained' size='small' onClick={() => handleOption(leadDetailOptions.Whatsapp)}>Whatsapp</Button> */}
            <Button sx={{ display: { md: 'none' }, height: '28px' }} variant='contained' size='small' onClick={handleClose}>Fechar</Button>
          </Grid>
        </Grid>
        {/* Card CLIENTES */}
        {showIdentification && (
          <ClinteDetalhe
            account={account}
            lead={lead}
            showClient={showIdentification}
            setShowClient={setShowIdentification}
            setShowTaskList={setShowTaskList}
            setSnackbar={setSnackbar}
          />
        )}

        {/* Card TAREFAS */}
        {showTasklList && (
          <LeadTaskSelect
            account={account}
            lead={lead}
            setSnackbar={setSnackbar}
            setLoading={setLoading}
            setUpdate={setUpdate}
            update={update}
            setShowMenu={setShowMenu}
            setShowMyLeads={setShowMyLeads}
            setShowLeadDetails={setShowLeadDetails}
          />
        )}
        {/* Card DETALHES DA TAREFA */}
        {showTask && (
          <LeadTaskDetails
            account={account}
            lead={lead}
            task={task}
            setTask={setTask}
            taskList={taskList}
            taskListFiltered={taskListFiltered}
            filtro={filtro}
            showTask={showTask}
            setShowTask={setShowTask}
            setSnackbar={setSnackbar}
            setUpdate={setUpdate}
            update={update}
          />
        )}
        {/* Card WHATSAPP */}
        {showWhatsapp && (
          <Grid container
            height={'540px'}
            width={'550px'}
          >
            <WhastForm
              account={account}
              lead={lead}
              setSnackbar={setSnackbar}
              setLoading={setLoading}
              setShowWhatsapp={setShowWhatsapp}
              showWhatsapp={showWhatsapp}
              setShowTaskList={setShowTaskList}
            />
          </Grid>
        )}

      </Grid>
    </Grid >
  );
}
