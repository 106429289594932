import { LoadingButton } from '@mui/lab'

import { Button, Card, CardContent, createTheme, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TextField, ThemeProvider, Typography, useTheme } from '@mui/material';
import { useState } from 'react';
import { removeOperatorController, saveEquipeNome, setOperadorController } from '../../../controllers/equipeController';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import SaveTwoToneIcon from '@mui/icons-material/SaveTwoTone';



export function CadEquipe({ user, listUsuarios, setListUsuarios, getAllOperadores }) {

  const conta = user.conta
  let [usuario, setUsuario] = useState()
  useTheme()
  // Estrutra da Tabela de Dados
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const rows = []

  const columns = [
    { idop: 'nome', label: 'Nome', minwidth: 170 },
    { idop: 'email', label: 'Email', minwidth: 170 },
    { idop: 'rules', label: 'Perfil', minwidth: 170 },
  ];

  // const [equipe, setEquipe] = useState([])
  // const [hasConviteValido, setHasConviteValido] = useState(false)

  function createData(idop, id, nome, email, rules) {
    var perfil = ''
    if (rules === 'consulta') perfil = 'Consulta'
    if (rules === 'cadastro') perfil = 'Cadastro'

    return { idop, id, nome, email, rules };
  }

  // Tabela de Dados
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  if (listUsuarios.length > 0) {
    listUsuarios.forEach(element => {
      rows.push(createData(element.idop, element.id, element.nome, element.email, element.rules))
    });
  }

  // Equipe

  const [isLoadingNome, setIsLoadingNome] = useState(false)
  const [nomeEquipe, setNomeEquipe] = useState()
  const [altEquipe, setAltEquipe] = useState(false)

  async function gravarNomeEquipe() {
    setIsLoadingNome(true)
    const result = await saveEquipeNome(conta, nomeEquipe)
    if (result.sucess) {
      user.equipe = nomeEquipe
      setAltEquipe(false)
    }
    setIsLoadingNome(false)
  }

  // Operador Selecionado

  // Seleção de Usuario
  let perfil = ''
  function handleSelectUsuario(row) {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('usuario', row)
    perfil = row.rules[0]
    setSelect(perfil)
    setUsuario(row)
  }

  const [select, setSelect] = useState('consulta')
  function handlePerfilChange(event) {
    setSelect(event.target.value)
  }

  // Remove Operador
  const [isLoadingExc, setIsLoadingExc] = useState(false)
  const [openExcluir, setOpenExcluir] = useState(false)
  async function handleExcluir() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(usuario, conta)
    await removeOperatorController(conta, usuario.idop, usuario.id)
    getAllOperadores()
    setOpenExcluir(false)
    setUsuario(null)
  }

  //Gravar Usuario
  const [isLoadingUser, setIsLoadingUser] = useState(false)
  async function handleGravar() {
    usuario.rules = [select]
    setIsLoadingUser(true)
    await setOperadorController(usuario, conta)
    await getAllOperadores()
    setIsLoadingUser(false)
  }

  const theme = createTheme({
    palette: {
      background: {
        paper: '#fff',
      },
      text: {
        primary: '#173A5E',
        secondary: '#46505A',
      },
      action: {
        active: '#001E3C',
      },
      success: {
        main: '#4CAF50',
        dark: '#009688',        
      },
    },
  });


  return (
    <ThemeProvider theme={theme}>
      <Grid
        container
        // width={'100%'}
        direction={'column'}
      >
        <Grid
          container
          justifyContent={'space-between'}
        // sx={{ backgroundColor: 'gray' }}
        >
          <TextField
            sx={{ width: '80%' }}
            id="equipe"
            name='equipe'
            size='small'
            label='Nome da equipe'
            defaultValue={user && user.equipe}
            onBlur={(event) => { setNomeEquipe(event.target.value) }}
            onChange={() => { setAltEquipe(true) }}

          />
          <LoadingButton
            id='btEquipe'
            size="small"
            color="primary"
            onClick={gravarNomeEquipe}
            loading={isLoadingNome}
            loadingPosition="center"
            variant="contained"
            disabled={!altEquipe}
            startIcon={<SaveTwoToneIcon />}
          // sx={{ p: 1 }}
          > Gravar
          </LoadingButton>
        </Grid>
        <Grid item my={2} >
          <Typography variant='subtitle1' mb={1}>Selecione um integrante:</Typography>
          <Paper
            // style={{ maxHeight: '200px' }} 
            sx={{ overflow: 'auto', maxHeight: '200px', height: '200px' }}>
            <TableContainer >
              <Table stickyHeader aria-label="Integrantes" size='small' >
                <TableHead >
                  <TableRow>
                    {columns.map((column) => (
                      <TableCell
                        key={column.idop}
                        align={column.align}
                        //sx={{ minWidth: column.minwidth, }}
                        hidden={true}
                      >
                        <Typography variant='button'>
                          {column.label}
                        </Typography>
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row) => {
                      return (
                        <TableRow hover role="checkbox"
                          tabIndex={-1}
                          key={row.id}
                          onClick={() => { handleSelectUsuario(row) }}
                        >
                          {columns.map((column) => {
                            const value = row[column.idop];
                            return (
                              <TableCell key={column.idop} align={column.align}>
                                <Typography variant='subtitle1'>
                                  {column.format && typeof value === 'number'
                                    ? column.format(value)
                                    : value}
                                </Typography>
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 100]}
              component="div"
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Grid>
        <Grid item >
          {/* Integrante Selecionado */}
          <Card
            // sx={{ width: '100%'  }} 
            elevation={2}>
            <CardContent>
              <Grid
                container
                direction={'column'}
              // width={'100%'}
              // justifyContent={'space-between'}
              >
                <Grid item width={'100%'} my={'5px'}>
                  <Typography variant='subtitle1'>Integrante selecionado:</Typography>
                </Grid>
                <Grid
                  container
                  direction={'column'}
                // alignItems={'center'} 
                // sx={{ width: '100%' }}
                >
                  <Grid
                    container
                    justifyContent={'space-between'}
                    alignItems={'center'}
                  >
                    <Grid
                      item
                      sx={{ p: 1, border: '1px dashed grey', width: '200px', minWidth: '150px' }}
                    >
                      <Typography variant='body1' >
                        {usuario ? usuario.nome : 'Nome'}
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      sx={{ m: 1, p: 1, border: '1px dashed grey', width: '200px', minWidth: '150px', overflow: 'hidden' }}
                    >
                      <Typography variant='body1'>
                        {usuario ? usuario.email : 'Email'}
                      </Typography>
                    </Grid>
                    <FormControl
                      sx={{
                        m: 1,
                        minWidth: 200
                      }}>
                      <InputLabel id="lbPerfil">Perfil</InputLabel>
                      <Select
                        name='perfil'
                        id="perfil"
                        labelId="lbPerfil"
                        label='Perfil'
                        value={select}
                        onChange={handlePerfilChange}
                        size='small'
                        disabled={usuario ? false : true}
                      >
                        <MenuItem value={'cadastro'}>Cadastro</MenuItem>
                        <MenuItem value={'consulta'}>Consulta</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>


                </Grid>
              </Grid>
              <Grid
                container
                // width={'100%'}
                justifyContent={'end'}
              // sx={{ '& > button': { m: 1 } }}
              >
                <LoadingButton
                  id='btExcluir'
                  size="small"
                  color="primary"
                  onClick={() => setOpenExcluir(true)}
                  loading={isLoadingExc}
                  loadingPosition="start"
                  variant="contained"
                  startIcon={<DeleteTwoToneIcon />}
                  disabled={usuario ? false : true}
                  sx={{ maxHeight: '50px', mr: 1 }}
                > Excluir
                </LoadingButton>
                <LoadingButton
                  id='btGravar'
                  size="small"
                  color="primary"
                  onClick={handleGravar}
                  loading={isLoadingUser}
                  loadingPosition="start"
                  variant="contained"
                  startIcon={<SaveTwoToneIcon />}
                  disabled={usuario ? false : true}
                  sx={{ maxHeight: '50px' }}
                >
                  Gravar
                </LoadingButton>
              </Grid>
            </CardContent>
          </Card>

          {/* Dialog Retirar Usuário da Equipe */}
          {usuario && (
            <Dialog
              open={openExcluir}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Retirar da equipe o usuário?"}
              </DialogTitle>

              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <Typography variant='body1' component={'span'} color={'error'}>
                    {usuario.nome.toUpperCase()} ({usuario.email})?
                  </Typography>
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={handleExcluir} autoFocus>Sim</Button>
                <Button onClick={() => setOpenExcluir(false)}>Não</Button>
              </DialogActions>
            </Dialog>
          )}

        </Grid>
      </Grid>
    </ThemeProvider>

  )
}