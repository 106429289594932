

export const leadProcessoStatus = Object.freeze({
  Manual: 'm',
  Automatico: 'a',
  Parcial: 'p',
  Description: {
    m: 'Manual',
    a: 'Automático',
    p: 'Parcial',
  }
})


export const leadOrigemStatus = Object.freeze({
  Whatsapp: 'wa',
  ChatBot: 'cb',
  Instagram: 'in',
  Facebook: 'fa',
  Telegram: 'te',
  Tiktok: 'ti',
  Description:{
    wa: 'Whatsapp',
    cb: 'ChatBot',
    in: 'Instagram',
    fa: 'Facebook',
    te: 'Telegram',
    ti: 'Tiktok',
  }
})

export const processFase = Object.freeze({
  Contato: 'Contato',
  Analise: 'Análise de Crédito',
  Informacao: 'Informação',
  Aquisicao: 'Aquisição',
  Fechamento: 'Fechamento',
  Arquivo: 'Arquivo'
})


export const processFases = Object.freeze([
  { value: process.env.REACT_APP_KEY_CONTATO, description: 'Contato' },
  { value: process.env.REACT_APP_KEY_ANALISE, description: 'Análise de Crédito' },
  { value: process.env.REACT_APP_KEY_INFORMACAO, description: 'Informação' },
  { value: process.env.REACT_APP_KEY_AQUISICAO, description: 'Aquisição' },
  { value: process.env.REACT_APP_KEY_FECHAMENTO, description: 'Fechamento' },
  { value: process.env.REACT_APP_KEY_ARQUIVO, description: 'Arquivo' },
])
export const getProcessFasesDescription = (value) => processFases.find((p) => p.value === value).description

export const taskPriority = Object.freeze({
  Normal: 'n',
  Alta: 'a',
  Baixa: 'b',
  Description: {
    b: 'Baixa',
    n: 'Normal',
    a: 'Alta',

  },
  Ordem: {
    b: 1,
    n: 2,
    a: 3,

  },
  Ord: {
    1: 'b',
    2: 'n',
    3: 'a',
  }
})

export const taskType = Object.freeze({
  Tarefa: 'ta',
  Lembrete: 'le',
  Reuniao: 're',
  Description: {
    ta: 'Tarefa',
    le: 'Lembrete',
    re: 'Reuniao',
  }
})

export const taskAction = Object.freeze({

  whatsapp: 'whatsapp',
  telefone: 'telefone',
  email: 'email',
  encaminhar: 'encaminhar',
  documentacao: 'documentacao',
  visita: 'visita',
  reuniao: 'reuniao',
  contrato: 'contrato',
  assinatura: 'assinatura',
  venda: 'venda',
  outra: 'outra',
  Description: {
    whatsapp: 'Contatar por Whatsapp',
    telefone: 'Contatar por telefone',
    encaminhar: 'Encaminhar material',
    documentacao: 'Solicitar documentação',
    email: 'Enviar email',
    visita: 'Marcar Visita',
    reuniao: 'Marcar Reunião',
    contrato: 'Enviar contrato',
    assinatura: 'Marcar assinatura do contrato',
    venda: 'Venda Efetivada',
    outra: 'Outra',
  }
})


export const taskStatus = Object.freeze({
  Pendente: 'pe',
  Concluida: 'co',
  Suspensa: 'su',
  Description: {
    pe: 'Pendente',
    co: 'Concluida',
    su: 'Suspensa',
  }
})

export const leadDetailOptions = Object.freeze({
  Whatsapp: 'whatsapp',
  Task: 'task',
  TaskList: 'tasklist',
  Identification: 'identification',
  Gimo: 'gimo',
  Description: {
    whatsapp: 'Whatsapp',
    task: 'Tarefa',
    identification: 'Identificação',
    gimo: 'GIMO',
  }
})

export const leadStatus = Object.freeze({
  Disponivel: 'di',
  Designado: 'de',
  Cancelado: 'ca',
  Excluido: 'ex',
  Transferido: 'tr',
  Encerrado: 'en',
  Description: {
    di: 'Disponível',
    de: 'Designado',
    ca: 'Cancelado',
    ex: 'Excluído',
    tr: 'Transferido',
    en: 'Encerrado',
  }
})

export function getLeadStatusDescription(status) {
  return leadStatus.Description[status]
}



export const operadorStatus = Object.freeze({
  Disponivel: 'di',
  Indisponivel: 'in',

})

export const comissaoStatus = Object.freeze({
  Pendente: 'pe',
  Pago: 'pa',
  Cancelado: 'ca',
})

export const escalaStatus = Object.freeze({
  Prevista: 'pv',
  Ordenada: 'or',
  Programada: 'pg',
  Executando: 'ex',
  Suspensa: 'su',
  Desativada: 'de',
})

export const distribuicaoTipo = Object.freeze({
  Randomica: 'r',
  Cadastro: 'c',
})

export const escalaTipo = Object.freeze({
  Automatica: 'a',
  Manual: 'm'
})
