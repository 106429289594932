import axios from 'axios'

export const apiGate = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: process.env.REACT_APP_GATE_KEY },
})

export const appGate = axios.create({
  baseURL: process.env.REACT_APP_APP_URL,
  headers: { Authorization: process.env.REACT_APP_GATE_KEY },
})

export const apiChatBot = axios.create({
  baseURL: process.env.REACT_APP_API_CHATBOT,
  headers: { Authorization: process.env.REACT_APP_GATE_KEY },
})

export const apiGate2 = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: process.env.REACT_APP_GATE_KEY2 },
})


export const apiStreamGate = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: { Authorization: process.env.REACT_APP_GATE_KEY },
  responseType: 'stream'
})