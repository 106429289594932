import { Box, Checkbox, Divider, FormControl, FormControlLabel, Grid } from '@mui/material'
import { useEffect, useState } from 'react'
import { TextFieldMask } from '../../../utils/TextfieldMask/TextfieldMask'
import { poolFacilidades } from '../../../constants/places'

export const Caracteristicas2 = ({ setPlaceEdit, placeEdit, setNewPlace, newPlace }) => {

  // console.log('placeEdit', placeEdit)

  const [caracteristica, setCaracteristica] = useState(placeEdit)


  // console.log('caracteristica', caracteristica)

  useEffect(() => {
    // console.log('caracteristica', caracteristica)

    setPlaceEdit(caracteristica)
  }, [caracteristica])

  
  // const poolCaracteristicas = [
  //   { 'nome': 'Suíte', 'campo': 'suite' },
  //   { 'nome': 'Bicicletário', 'campo': 'bicicletario' },
  //   { 'nome': 'Port-cochère', 'campo': 'portcochere' },
  //   { 'nome': 'Beach Tênis', 'campo': 'beachtenis' },
  //   { 'nome': 'Sacada', 'campo': 'sacada' },
  //   { 'nome': 'Elevador', 'campo': 'elevador' },
  //   { 'nome': 'Piscina', 'campo': 'piscina' },
  //   { 'nome': 'Salão de festas', 'campo': 'salafesta' },
  //   { 'nome': 'Churrasqueira', 'campo': 'churrasqueira' },
  //   { 'nome': 'Quiosque com churrasqueira', 'campo': 'quiosquechur' },
  //   { 'nome': 'Playground', 'campo': 'playgroud' },
  //   { 'nome': 'Espaço kids', 'campo': 'espacokids' },
  //   { 'nome': 'Salão de jogos', 'campo': 'salajogos' },
  //   { 'nome': 'Espaço gourmet', 'campo': 'gourmet' },
  //   { 'nome': 'Vaga fixa', 'campo': 'vagafixa' },
  //   { 'nome': 'Vaga rotativa', 'campo': 'vagarotativa' },
  //   { 'nome': 'Garden', 'campo': 'garden' },
  //   { 'nome': 'Academia', 'campo': 'academia' },
  //   { 'nome': 'Quadra esportiva', 'campo': 'qdesportiva' },
  //   { 'nome': 'Quadra de tênis', 'campo': 'qdtenis' },
  //   { 'nome': 'Quadra de vôlei', 'campo': 'qdvolei' },
  //   { 'nome': 'Esporte bar', 'campo': 'esportebar' },
  //   { 'nome': 'Pet care', 'campo': 'petcare' },
  //   { 'nome': 'Pet place', 'campo': 'petplace' },
  //   { 'nome': 'Sala multiuso', 'campo': 'salamult' },
  //   { 'nome': 'Coworking', 'campo': 'coworking' },
  //   { 'nome': 'Marketplace', 'campo': 'marketplace' },
  //   { 'nome': 'Lavanderia', 'campo': 'lavanderia' },
  //   { 'nome': 'Sauna', 'campo': 'sauna' },
  //   { 'nome': 'Rooftop', 'campo': 'rooftop' },
  //   { 'nome': 'Cinema', 'campo': 'cinema' }
  // ]

  function mountFacilities(item,i) {

    return (
      <FormControlLabel
        key={item.campo}
        sx={{ width: '155px' }}
        control={<Checkbox id={item.campo} checked={caracteristica?.[item.campo] ? caracteristica[item.campo] : false} onChange={(e) => setCaracteristica({ ...caracteristica, [item.campo]: e.target.checked })} />}
        label={item.nome}
      />
    )
  }

  return (
    <Box>
      <Divider />
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column', 
        padding: '10px', 
        }}>
        <FormControl
          sx={{ 
            display: 'flex', 
            flexDirection: 'row', 
            justifyContent: 'space-between', 
            // marginTop: '10px', 
            // marginBottom: '10px', 
            // gap: '10px' 
          }}
        >
          <TextFieldMask
            label="Dorm. De:"
            variant="standard"
            mask="number"
            value={caracteristica.quartosqtdmin ? caracteristica.quartosqtdmin : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, quartosqtdmin: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, quartosqtdmin: e.target.value })}
            sx={{ marginBottom: '10px', width: '80px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Dorm. Até:"
            variant="standard"
            mask="number"
            value={caracteristica.quartosqtdmax ? caracteristica.quartosqtdmax : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, quartosqtdmax: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, quartosqtdmax: e.target.value })}
            sx={{ marginBottom: '10px', width: '80px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Área Menor"
            variant="standard"
            mask="decimal"
            value={caracteristica.minarea ? caracteristica.minarea : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, minarea: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, minarea: e.target.value })}
            sx={{ marginBottom: '10px', width: '70px' }}
            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Área Maior"
            variant="standard"
            mask="decimal"
            value={caracteristica.maxarea ? caracteristica.maxarea : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, maxarea: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, maxarea: e.target.value })}
            sx={{ marginBottom: '10px', width: '70px' }}
            inputProps={{ maxLength: 6, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Pavimentos"
            variant="standard"
            mask="number"
            value={caracteristica.andares ? caracteristica.andares : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, andares: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, andares: e.target.value })}
            sx={{ marginBottom: '10px', width: '70px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
          <TextFieldMask
            label="Torres"
            variant="standard"
            mask="number"
            value={caracteristica.blocos ? caracteristica.blocos : ""}
            onChange={(e) => setCaracteristica({ ...caracteristica, blocos: e.target.value })}
            onBlur={(e) => setCaracteristica({ ...caracteristica, blocos: e.target.value })}
            sx={{ marginBottom: '10px', width: '40px' }}
            inputProps={{ maxLength: 2, style: { textAlign: 'center' } }}
          />
        </FormControl>
        <Grid container spacing={0} direction={'row'} justifyContent={'center'} >
          <Grid container direction={'row'} 
          height={'42vh'}
          overflow={'auto'}
          // width={'80%'} 
          >
            {
            poolFacilidades.map((item,i) => {
              return mountFacilities(item)
            })}
          </Grid>
        </Grid>
      </Box>
    </Box>

  )
}