import { doc, getDoc } from 'firebase/firestore'
import { apiGate } from '../../services/api_gate'
import { saveBaseConhecimento } from '../placeController'
import { db } from '../../firebase'

export async function listarCampanhas(dados) {
    try {
        // console.log('Entrou do listarCampanhas',dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do listarCampanhas') }
        const api = await apiGate.post('/crm/campanha/list', dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
        return api
    }
    catch (e) {
        return null
    }
}

// Função para salvar as campanhas
export async function salvarCampanha(user, dados) {
    try {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do salvarCampanha',dados) }
        const _knowledge = dados.knowledge
        if (dados.knowledge || dados.knowledge === '' || dados.knowledge === undefined) {
            delete dados.knowledge
        }
        const api = await apiGate.post('/crm/campanha/save', dados)
        if (api.status === 200) {
            if (_knowledge) {
                const result = await saveBaseConhecimento(user, { id: api.data.idcampanha, know: _knowledge })
                if (result.sucess) {
                    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Base de conhecimento salva com sucesso.')
                }
                else {
                    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(result.msg, result.data)
                }
            
            }
        }
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
        return api
    }
    catch (e) {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro', e)
        return { status: 500, message: e.message }
    }
}

// Função para carregar a base de conhecimento do firestore passando a conta e o id da campanha
export async function carregarBaseConhecimento(user, id) {
    try {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do carregarBaseConhecimento', user, id) }
        const campanhaDocRef = doc(db, 'contas/' + user.conta + '/knowledge/' + id)
        const campanhaDoc = await getDoc(campanhaDocRef)
        if (campanhaDoc.exists()) {
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Document data:', campanhaDoc.data())
            return campanhaDoc.data()
        } else {
            // doc.data() will be undefined in this case
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('No such document!')
            return null
        }
    }
    catch (e) {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Erro', e)
        return null
    }
}


// função para deletar campanha
export async function deletarCampanha(dados) {
    try {
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) { console.log('Entrou do deletarCampanha') }
        const api = await apiGate.post('/crm/campanha/delete', dados)
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('api', api.data)
        return api
    }
    catch (e) {
        return null
    }
}