import { useState } from 'react'
import { distribuicaoTipo, escalaTipo } from '../../../constants/crm'

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@mui/material'
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { getDistribuicao } from '../../../utils/crm/getDistribuicao';
import { updateEscala } from '../../../controllers/crm/escalaController';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br';
dayjs.locale('pt-br');

export function EditarEscala(
  { setOpenDialogEditar,
    openDialogEditar,
    idconta,
    idusuario,
    setSnackbar,
    listEscalas,
    escala,
  }) {


  // const [periodoOuDiaria, setPeriodoOuDiaria] = useState('d') // d -> Diária, p -> Período
  const [tpDistribuicao, setTpDistribuicao] = useState(escala.tpdistribuicao)
  const [tpEscala, setTpEscala] = useState(escalaTipo.Manual) // a -> Automática, m -> Manual
  const [startDay, setStartDay] = useState(dayjs(escala.diamesano))
  // const [endDay, setEndDay] = useState()
  const [load, setLoad] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [noEdit, setNoEdit] = useState(true)
  const [hrInicial, setHrInicial] = useState(dayjs(escala.hrinicial))
  const [hrFinal, setHrFinal] = useState(dayjs(escala.hrfinal))



  const handleNo = () => {
    setOpenDialogEditar(false);
  };

  const handleGravar = async () => {
    try {
      setDisabled(true)
      setLoad(true)
      const d = new Date().toLocaleDateString() + ' ' + new Date().toLocaleTimeString()
      const dados = {
        idescala: escala.idescala,
        version: escala.version,
        hrinicial: dayjs(startDay.format('YYYY-MM-DD') + ' ' + hrInicial.format('HH:mm')).toISOString(),
        hrfinal: dayjs(startDay.format('YYYY-MM-DD') + ' ' + hrFinal.format('HH:mm')).toISOString(),
        tpdistribuicao: tpDistribuicao,
        idusuario: idusuario,
        historico: `# ${d} - Escala Editada (M)` + '\n ' + escala.historico
      }
      const response = await updateEscala(dados);
      console.log('response', response)
      if (response.status === 200) {
        escala.hrinicial = dados.hrinicial
        escala.hrfinal = dados.hrfinal
        escala.tpdistribuicao = dados.tpdistribuicao
        escala.historico = dados.historico
        escala.version = response.data.version
        setSnackbar({ severity: 'success', message: 'Controle atualizado com sucesso!' });
      }
      else if (response.status === 210) {
        listEscalas()
        setSnackbar({ severity: 'error', message: 'Erro de acesso concorrente! Realize novamente a operação.' });
        setOpenDialogEditar(false);
        return;
      }
      else {
        setSnackbar({ severity: 'error', message: 'Erro ao atualizar o controle! Tente novamente mais tarde.' });
        return;
      }
    }
    catch (error) {
      setSnackbar({ severity: 'error', message: 'Serviço fora do ar, tente novamente mais tarde.' });
    }
    finally {
      setDisabled(false)
      setLoad(false)
    }
  };

  // useEffect(() => {
  //   console.log('escala', escala)
  // }, [])

  return (
    <Dialog
      maxWidth="xs"
      // TransitionProps={{ onEntered: handleEntered }}
      open={openDialogEditar}
      onClose={handleNo}
    >
      {(load) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <DialogTitle>
        <Typography variant="h4" component="div">
          CONTROLE do ON-LINE - EDIÇÃO
        </Typography>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container
          direction={'row'}
          minHeight={'220px'}
          maxWidth={'320px'}
        >
          <Grid container direction={'row'} >
            <Grid container direction={'column'} gap={1} >
              <Grid item marginBottom={1}>
                <DatePicker
                  label="Data"
                  disabled={noEdit}
                  value={startDay}
                  format='DD/MM/YYYY'
                  sx={{ width: '150px' }}
                />
              </Grid>
            </Grid>
            <Grid container direction={'row'} gap={1} >
              <Grid item >
                <TimePicker
                  label="Hr Inicial"
                  ampm={false}
                  value={hrInicial}
                  onChange={(date) => setHrInicial(date)}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  sx={{ width: '150px' }}
                />
              </Grid>
              <Grid item >
                <TimePicker
                  label="Hr Final"
                  value={hrFinal}
                  ampm={false}
                  onChange={(date) => setHrFinal(date)}
                  views={['hours', 'minutes']}
                  format="HH:mm"
                  sx={{ width: '150px' }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid container direction={'column'} marginTop={1} >
            <Typography variant="body1" fontSize={14} component="div" marginRight={'5px'}>
              Ordem dos Corretores:
            </Typography>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue={escala.tpdistribuicao}
              value={tpDistribuicao}
              name="radio-buttons-group"
              onChange={(e) => setTpDistribuicao(e.target.value)}
            >
              <div>
                <FormControlLabel value={distribuicaoTipo.Randomica} control={<Radio />} label={getDistribuicao(distribuicaoTipo.Randomica)} />
                <FormControlLabel value={distribuicaoTipo.Cadastro} control={<Radio />} label={getDistribuicao(distribuicaoTipo.Cadastro)} />
              </div>
            </RadioGroup>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container justifyContent={'flex-end'} gap={2}>
          <Button variant="contained" onClick={handleGravar} disabled={disabled} size='small' >
            Gravar
          </Button>
          <Button variant="contained" onClick={handleNo} disabled={disabled} size='small'>
            Fechar
          </Button>
        </Grid>
      </DialogActions>
    </Dialog>
  );
};