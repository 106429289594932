import { useEffect, useState } from 'react'
import { Alert, Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, TextField, Typography, useTheme } from '@mui/material'
import { generateAIText } from '../../api/generateAIText'
import { generatePrompt } from '../../api/generatePrompt'


import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';

import ListAltTwoToneIcon from '@mui/icons-material/ListAltTwoTone';
import ArticleTwoToneIcon from '@mui/icons-material/ArticleTwoTone';
import RequestPageTwoToneIcon from '@mui/icons-material/RequestPageTwoTone';
import TextSnippetTwoToneIcon from '@mui/icons-material/TextSnippetTwoTone';
import HandshakeTwoToneIcon from '@mui/icons-material/HandshakeTwoTone';

import { red } from '@mui/material/colors'
import { getMessagesAI, saveMessageAI, setAjustarCredito, setBloquearCredito } from '../../controllers/messageAIControler';
import { Typewriter } from '../../utils/Typewriter';



export function AssistenteAI({ openAI, setOpenAI, place, user, showMySnackbar }) {

  const theme = useTheme();

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrei em Assistente AI', user)

  let text = '( ---- MODELO EXEMPLIFICATIVO GERADO PELO ASSISTENTE IA ---- )\n\nOlá, Sr. NOME DO CLIENTE!\n\nSou o corretor de imóveis NOME DO CORRETOR, da Imobiliária NOME DA EQUIPE, e estou entrando em contato para apresentar o empreendimento NOME DO EMPREENDIMENTO, construído pela NOME DA CONSTRUTORA.\nEsse empreendimento conta com 4 torres, cada uma com 10 andares, e está localizado no ENDEREÇO. As metragens dos imóveis variam de 45.00m2 até 78.5m2, com opções de 1 até 3 quartos. \n\nO investimento para adquirir um imóvel no NOME DO EMPREENDIMENTO varia entre R$ 175.000,00 até R$ 185.000,00, aproximadamente, de acordo com a referência de valores do mês de fevereiro de 2019. Com uma renda de R$ 1.500,00, você pode se tornar proprietário de um imóvel nesse empreendimento. \n\nO NOME DO EMPREENDIMENTO está em obras e a previsão de entrega é para junho de 2025. \nPara mais informações, acesse nosso site WWW.SITECONSTRUTORA.COM.BR e confira todas as facilidades que o empreendimento oferece, como academia, churrasqueira, lavanderia, piscina, quadra esportiva, sacada, vaga fixa, térreo com jardim, entre outras opções. \n\nCaso queira ver mais detalhes sobre o empreendimento, acesse o link https://drive.google.com/drive/folders/15_9sbkcrLe4DR1y1J0XrqjbSH9XNNQ7C. \n\nEstou à disposição para agendar uma visita e tirar todas as suas dúvidas. \n\nAtenciosamente, \nCorretor de Imóveis NOME DO CORRETOR \nNOME DA EQUIPE. '
  let msgSelecione = 'Olá!\n\n Selecione um tipo de documento...\n\n Edite seu conteúdo, ou pressione GERAR para eu trabalhar um pouquinho...'

  const [tpMessage, setTpMessage] = useState()
  const [messages, setMessages] = useState([])
  const [message, setMessage] = useState()
  const [tokens, setTokens] = useState(0)
  const [credito, setCredito] = useState(user.credito || 0)
  const [leto, setLeto] = useState(500)
  const [isLoading, setIsLoading] = useState(false)
  const [wasChanged, setWasChanged] = useState(false)
  const [showMessageChanged, setShowMessageChanged] = useState(false)
  const [position, setPosition] = useState()
  const [activateAI, setActivateAI] = useState(false)
  // const [sayAI, setSayAI] = useState('credito')
  const [corIcon, setCorIcon] = useState(red[500])

  const msgsIniciais = ['Olá!', 'Sou o GIM, seu assistente virtual...',
    'Posso te auxiliar na elaboração de alguns documentos e nesta versão consigo elaborar os seguintes rascunhos:', '\n',
    'Modelo de Apresentação Pessoal:',
    ' - Mensagem para abordagem inicial de apresentação do corretor;',
    'Modelo de Apresentação do Imóvel:',
    ' - Mensagem para apresentar o empreendimento ao cliente, sem as informações financeiras;',
    'Modelo Financeiro:',
    ' - Mensagem para apresentar os dados financeiros do imóvel;',
    'Modelo Completo',
    ' - Mensagem com todos os dados do imóvel para o encaminhamento da negociação.', '\n',
    'Como?',
    'Selecione um dos ícones abaixo (Apresentação, Negociação ou Completo) e pressione a opção GERAR. Quando pronto, faça as alterações que julgar necessárias.',
    'Depois pressione a opção GRAVAR para manter o documento em seu arquivo.', '\n',
    'Pressione COPIAR para transferir o texto para área de transferência, para poder colar em uma conversa do whatsapp, por exemplo.'
  ]
  const msgsCredito = ['\n', 'Olá!', '\n',
    'Para que eu possa prestar meus serviços.... vou precisar de créditos!'
  ]
  const [msgAI, setMsgAI] = useState(msgsIniciais)


  const handleChangeTpMessage = (event, newValue) => {

    if (wasChanged) {
      setPosition(newValue)
      setShowMessageChanged(true)
    }
    else {
      setTpMessage(newValue);
      if (messages) selecionaMessage(newValue)
    }
  };

  function exitWithoutSave() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('exit', position)
    setShowMessageChanged(false)
    setWasChanged(false)
    setTpMessage(position)
    if (messages) selecionaMessage(position)
  }

  function changeMessage(event) {
    setMessage(event.target.value)
    setWasChanged(true)
  }

  function selecionaMessage(_tpmessage) {
    setMsgAI('')
    let msg = messages.find(msg => msg.id === _tpmessage)
    if (msg) {
      setMessage(msg.message)
      setTokens(msg.tokens)
    }
    else {
      if (_tpmessage === 'modelo') {
        setMessage(text)
      }
      else {
        setMessage(msgSelecione)
      }
      setTokens(0)
    }
    setActivateAI(false)
  }

  async function generateMessageAI() {


    if (!user.credito || user.credito <= 0) {
      setMsgAI(msgsCredito)
      setActivateAI(true)
      return
    }
    setIsLoading(!isLoading)
    const CEMTO = leto + (leto / 3) // Custo Estimado Majorado de Tokens da Operação ()
    let LETO = leto  // Limite Estimado de Tokens para a operação
    let CEO = 0 // Custo Efetivo da Operação
    let DOR = 0 // Diferença da Operação Realizada
    let MSG = '' // Mensagem de Retorno AI
    let CRD = 0 // Credito da Conta
    let CEOT = 0  //Custo Efetivo Mais Taxa
    const Tx = 20 // Taxa de Processamento e  de Tráfego
    const ccredito = await setBloquearCredito(user.id, CEMTO) // Retorna crédito atualizado da conta
    if (ccredito.sucess) { // Bloqueio realizado com sucesso
      // Atualiza crédito corrente
      CRD = ccredito.data
      user.credito = CRD
      // Consome o serviço de AI
      try {
        const response = await generateAIText(generatePrompt(place, user, 'NOME CLIENTE', tpMessage), LETO).then(async (chat) => {
          if (chat.choices.length > 0) {
            // Atualiza as variáveis de controle
            CEO = chat.usage.total_tokens
            CEOT = CEO + ((CEO * Tx) / 100)
            DOR = CEMTO - CEO
            MSG = chat.choices[0].message.content
            if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(chat.choices[0].message.content, chat.usage)
          }
          // Avalia a diferença entre CEMTO e CEO (DOR)
          if (DOR !== 0) {
            // Ajusta o crédito pela diferença do valor efetivo
            const rst = await setAjustarCredito(user.id, DOR)
            // Sucesso na operação de ajuste de crédito
            if (rst.sucess) {
              CRD = rst.data
              user.credito = CRD
            }
            else { // Falha na operação de ajuste de crédito
              // Registrar em log de controle
              // Falha no estorno do crédito da conta vlr DOR  (IDCONTA E VALOR DE DOR)
              if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha no estorno do crédito da conta vlr:', DOR)
            }
          }
          setMessage(MSG)
          setTokens(CEO)
          setCredito(CRD)
          setWasChanged(true)
          showMySnackbar(ccredito.msg, 2000, 'success')
        }).catch((e) => {
          showMySnackbar(e.message, 2000, 'error' )
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha no serviço OpenAI:', e.message)
        })
      }
      catch (e) {
        // Falha na operação de AI
        // realizar o estorno do Credito bloqueado 
        if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha no estorno do crédito da conta vlr:', e)
        const rst = await setAjustarCredito(user.id, -CEMTO)
        if (!rst.sucess) {
          // Registrar em log de controle
          // Falha no estorno do crédito da conta vlr CEMTO (IDCONTA E VALOR DE CEMTO)
          if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Falha no estorno do crédito da conta vlr:', CEMTO)
        }
        showMySnackbar(e, 2000, 'error')
      }
    }
    else { // Operador sem credito para a operação
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Operador sem crédito', ccredito)
      // user.credito = rst.data + CEMTO
      // setCredito(rst.data + CEMTO)
      showMySnackbar(ccredito.msg, 4000, 'error')
    }
    setIsLoading(false)
  }

  function defineLETO(data = [], tipo) { // Limite Estimado de Tokens para a Operação
    let _leto = 500
    let t = 0
    if (data.length > 0) {
      if (tipo === 'med') {
        for (let i = 0; i < data.length; i++) {
          t = data[i].tokens + t
        }
        _leto = t / data.length
      }
      if (tipo === 'min') {
        const m = data.reduce(function (prev, current) {
          return prev.tokens < current.tokens ? prev : current;
        })
        _leto = m.tokens
      }
      if (tipo === 'max') {
        const m = data.reduce(function (prev, current) {
          return prev.tokens > current.tokens ? prev : current;
        })
        _leto = m.tokens
      }
    }
    setLeto(Math.ceil(_leto))
  }

  async function loadMessagesAI() {
    const rst = await getMessagesAI(user.conta, place.id)
    if (rst.sucess) {
      if (rst.data.length > 0) {
        defineLETO(rst.data, 'med')
        setMessages(rst.data)
        selecionaMessage(tpMessage)
      }
      else {
        setActivateAI(true) // Sem mensagens gravadas ativa o Assistente
      }
    }
  }

  async function gravarMessageAI() {
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(user.conta, place.id, tpMessage, message, user.id, tokens)
    const rst = await saveMessageAI(user.conta, place.id, tpMessage, message, user.id, tokens)
    if (rst.sucess) {
      let _msg = messages.find(msg => msg.id === tpMessage)
      if (_msg) {
        _msg.message = message
        _msg.tokens = tokens
      }
      else {
        _msg = {
          id: tpMessage,
          message: message,
          tokens: tokens,
          ativo: true,
        }
        messages.push(_msg)
      }
      showMySnackbar(rst.msg, 2000, 'success')
      setWasChanged(false)
      setShowMessageChanged(false)
    }
    else {
      showMySnackbar(rst.msg, 2000, 'error')
      if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(rst.message)
    }

  }

  async function copyDivToClipboard() {
    var range = document.createRange();
    range.selectNode(document.getElementById("message_TextField"))
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log(range.toString().split('/ln').join('\n'))
    await navigator.clipboard.writeText(range.toString().split('/ln').join('\n')).then(() => {
      showMySnackbar('Copiado para área de transferência...', 2000, 'success')
    }).catch((e) => {
      showMySnackbar('Erro na operação', 2000, 'error')
    })
    // window.getSelection()
  }

  useEffect(() => {
    loadMessagesAI()
  }, [])

  return (
    <>
      <Dialog
        open={openAI}
        // onClose={handleClose}
        scroll={'paper'}
        aria-labelledby="IA"
        aria-describedby="IA"
        fullWidth
      >
        <DialogTitle id="IA">
          Assistente IA - Empreendimento {place.empreendimento}
        </DialogTitle>
        <DialogContent dividers sx={{ p: 0, m: 0 }}>
          <DialogContentText
            component={'div'}
            id="dialog"
            tabIndex={-1}
            height={'50vh'}
          >
            {activateAI && (
              <Box sx={{ p: 2, height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                <Typography variant='body1' component={'div'} >
                  {msgAI?.map((msg) => {
                    return (
                      <div><Typewriter key={msg} text={msg} delay={50} /><br /></div>
                    )
                  })}
                </Typography>
              </Box>
            )}


            {isLoading && (
              <>
                <Box sx={{ position: 'absolute', alignContent: 'flex-start', p: 2 }}>
                  <Typography variant='body1' sx={{ my: 1 }}>Ok!</Typography>
                  <Typography variant='body1'>
                    <Typewriter text="Gerando o modelo do documento solicitado..." delay={50} />
                  </Typography>
                </Box>
                <Box sx={{ position: 'relative', display: 'inline-flex', height: '100%', width: '100%', alignItems: 'center', justifyContent: 'center' }}>
                  <CircularProgress size={'10rem'} />
                  <Box
                    sx={{ position: 'absolute', alignItems: 'center', justifyContent: 'center' }}
                  >
                    <Typography variant="h1" component="div" color="text.secondary">
                      {'IA'}
                    </Typography>
                  </Box>
                </Box>
              </>
            )}

            {!isLoading && !activateAI && (
              <div style={{ height: '100%' }}>
                <Grid container width={'100%'} height={'100%'} >
                  <TextField
                    component={'div'}
                    fullWidth
                    id="message_TextField"
                    name="message_TextField"
                    multiline
                    rows={33}
                    value={message}
                    variant="standard"
                    onChange={changeMessage}
                    InputProps={{
                      readOnly: false,
                    }}
                    sx={{ p: 2, m: 0 }}
                  />
                  {/* <Typography ml={2} component={'span'} variant='body2'>Créditos consumidos: {tokens} - Créditos restantes:21352</Typography> */}
                </Grid>

              </div>

            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>

          <Grid container width={'100%'} flexDirection={'column'} >
            <Typography ml={2} component={'span'} variant='body2'>Créditos: {Math.ceil(credito)} - Créditos consumidos: {tokens} </Typography>
            {showMessageChanged && (
              <Alert
                severity="warning"
                action={
                  <Button onClick={exitWithoutSave} color="inherit" size="small">Sim</Button>
                }
              >
                As ultimas alterações não foram salvas, SERÃO PERDIDAS, sair mesmo assim?
              </Alert>
            )}

            <Grid container width={'100%'} overflow={'auto'}>
              <BottomNavigation

                value={tpMessage}
                onChange={handleChangeTpMessage}
                showLabels
                key={1}
                sx={{
                  [theme.breakpoints.up('sm')]: {
                    width: '100%'
                  },

                }}
              >
                <BottomNavigationAction
                  aria-colspan={0}
                  disabled={isLoading}
                  label="Apresentação Pessoal"
                  value="pessoal"
                  color={corIcon}
                  icon={<HandshakeTwoToneIcon />}
                  key={2}
                />
                <BottomNavigationAction
                  disabled={isLoading}
                  label="Apresentação Imóvel"
                  value="apresentacao"
                  color={corIcon}
                  icon={<TextSnippetTwoToneIcon />}
                  key={3}
                />
                <BottomNavigationAction
                  disabled={isLoading}
                  label="Financeiro"
                  value="financeiro"
                  icon={<RequestPageTwoToneIcon />}
                  key={4}
                />
                <BottomNavigationAction
                  disabled={isLoading}
                  label="Completo"
                  value="completo"
                  icon={<ArticleTwoToneIcon />}
                  key={5}
                />
                <BottomNavigationAction
                  disabled={isLoading}
                  label="Modelo"
                  value="modelo"
                  icon={<ListAltTwoToneIcon />}
                  key={6}
                />
              </BottomNavigation>
            </Grid>

            <Grid container flexDirection={'row'} justifyContent={'space-between'} width={'100%'} >
              <Button
                disabled={activateAI || isLoading}
                onClick={copyDivToClipboard}>Copiar</Button>
              <div>
                <Button
                  disabled={!tpMessage || tpMessage === 'modelo' || isLoading || place.id === 'gimov'}
                  onClick={gravarMessageAI}
                >Gravar</Button>
                <Button
                  disabled={!tpMessage || tpMessage === 'modelo' || isLoading || place.id === 'gimov'}
                  // disabled={!tpMessage || tpMessage === 'modelo'}
                  onClick={generateMessageAI}
                >Gerar</Button>
                <Button
                  disabled={isLoading}
                  onClick={() => { setOpenAI(!openAI) }}
                >Fechar
                </Button>
              </div>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </>
  )
}