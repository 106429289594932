import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid, Tab } from '@mui/material';
import { Identificacao } from './Identificacao';
import { PolVendas } from './PolVendas';
import { Caracteristicas2 } from './Caracteristicas2';
import { Nuvem } from './Nuvem';
import { useEffect, useState } from 'react';
import { useTheme } from '@emotion/react';
import { deletePlace, savePlace } from '../../../controllers/placeController';
import { deleteImgEmpreendimentos, sendImgEmpreendimentos } from '../../../api/storage';


export function InfoPlace({
  addMap,
  setAddMap,
  setOpenModal,
  newPosition,
  setNewPlace,
  newPlace,
  setHasPlaceNew,
  user,
  showMySnackbar
}) {

  if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Entrou em InfoPlace', newPlace)

  const theme = useTheme();

  const [placeEdit, setPlaceEdit] = useState(newPlace)
  const [wasModified, setWasModified] = useState(false)
  const [fileImg, setFileImg] = useState(null)

  // Funções da MODAL
  const handleCloseCad = () => {
    setNewPlace({})
    setOpenModal(false)
    if (wasModified) {
      setHasPlaceNew(Date.now())
      setAddMap(false)
    }

  }


  // Funções da TAB

  const [activeExcluir, setActiveExcluir] = useState(false)
  const [vtab, setTab] = useState('1');

  const handleTabChange = (event, newValue) => {
    setTab(newValue);
  }
  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  const [openExcluirDialog, setOpenExcluirDialog] = useState(false)
  const [disableButton, setDisableButton] = useState(false)

  const handleOpenExcluirDialog = () => {
    setOpenExcluirDialog(true);
  }
  async function handleClickExcluir() {
    setAddMap(false)
    setDisableButton(true)
    let rst = await deletePlace(newPlace, user).then(async (data) => {
      if (data.sucess) {
        await deleteImgEmpreendimentos(newPlace.id)
      }
      return data
    })
    if (rst.sucess) {
      setNewPlace(null)
      setOpenModal(false)
      setHasPlaceNew(Date.now())
      showMySnackbar(rst.msg, 4000, 'success')
    }
    else {
      showMySnackbar(rst.msg, 4000, 'error')
    }
  }

  // const handleClickCancel = () => {
  //   setNewPlace({})
  //   setAddMap(false)
  //   setOpenModal(false)
  // }

  // function saveImgEmpreendimento(file) {
  //   const imgRef = ref(storage, 'empreendimentos/' + newPlace.id)
  //   uploadBytes(imgRef, file).then(() => {
  //     console.log('Uploaded a blob or file!');
  //   })
  // }

  const handleClickGravar = async () => {
    setDisableButton(true)
    const file = placeEdit.file
    delete placeEdit.file
    // console.log('Gravação file', file)
    if (Boolean(process.env.REACT_APP_SHOWLOG === 'true')) console.log('Gravação placeEdit', placeEdit)

    let rst = await savePlace(placeEdit, user)
    if (rst.sucess) {
      if (file && rst.data) {
        await sendImgEmpreendimentos(rst.data, file).then(async (_url) => {
          placeEdit.urlimagem = _url
          await savePlace(placeEdit, user)
        })
      }
      setDisableButton(false)
      //   setNewPlace({})
      //   setHasPlaceNew(Date.now())
      //   setAddMap(false)
      //   setOpenModal(false)
      setWasModified(true)
      showMySnackbar(rst.msg, 500, 'success')
    }
    else {
      setDisableButton(false)
      // console.log('Falha em salvar', rst)
      showMySnackbar(rst.msg, 1500, 'error')
    }
  }

  useEffect(() => {
    // console.log('newPlace', newPlace)
    if (newPlace.id && newPlace.id !== 'gimov') {
      setActiveExcluir(true)
    }
    if (newPlace.id === 'gimov') { setDisableButton(true) }
  }, [])

  return (
    <Grid item position={'absolute'} >
      <Dialog
        id='DialogInfoPlace'
        open={addMap}
        onClose={handleCloseCad}
        scroll='paper'
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth='100%'
      >
        <DialogTitle id="scroll-dialog-title" fontSize={'16px'}>Empreendimento: {placeEdit.empreendimento}</DialogTitle>
        <DialogContent dividers={true} sx={{ width: '100%' }} >
          <DialogContentText component={'div'} id="scroll-dialog-description" >
            <TabContext value={vtab}>
              <Grid sx={{
                bgcolor: 'background.paper',
                [theme.breakpoints.down('sm')]: {
                  height: '70vh',
                  width: '90vw',
                },
                [theme.breakpoints.between('sm', 'lg')]: {
                  height: '70vh',
                  width: '60vw',
                },
                [theme.breakpoints.up('lg')]: {
                  height: '60vh',
                  width: '40vw',
                }
              }}
              >
                <TabList
                  value={vtab}
                  onChange={handleTabChange}
                  variant="scrollable"
                  scrollButtons='auto'
                  aria-label="Caracteristica do empreendimento"
                >
                  <Tab label="Identificação" value='1'
                    {...a11yProps(0)}
                  />
                  <Tab label="Política de Vendas" value='2'
                    {...a11yProps(1)}
                  />
                  <Tab label="Caracteristicas" value='3'
                    {...a11yProps(2)}
                  />
                  <Tab label="Nuvem" value={'4'}
                    {...a11yProps(3)}
                  />
                </TabList>
                <TabPanel value='1' index={0}>
                  <Identificacao
                    setPlaceEdit={setPlaceEdit}
                    placeEdit={placeEdit}
                    setNewPlace={setNewPlace}
                    newPlace={newPlace}
                    newPosition={newPosition}
                    openExcluirDialog={openExcluirDialog}
                    setOpenExcluirDialog={setOpenExcluirDialog}
                    handleClickExcluir={handleClickExcluir} />
                </TabPanel>
                <TabPanel value='2' index={1}>
                  <PolVendas
                    setPlaceEdit={setPlaceEdit}
                    placeEdit={placeEdit}
                    setNewPlace={setNewPlace}
                    newPlace={newPlace} />
                </TabPanel>
                <TabPanel value='3' index={2}>
                  <Caracteristicas2
                    setPlaceEdit={setPlaceEdit}
                    placeEdit={placeEdit}
                    setNewPlace={setNewPlace}
                    newPlace={newPlace} />
                </TabPanel>
                <TabPanel value='4' index={3}>
                  <Nuvem
                    setPlaceEdit={setPlaceEdit}
                    placeEdit={placeEdit}
                    setNewPlace={setNewPlace}
                    newPlace={newPlace}
                    setFileImg={setFileImg}
                    fileImg={fileImg}
                  />
                </TabPanel>
              </Grid>
            </TabContext>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Grid container direction={'row'} justifyContent={'flex-end'}>
            <Button
              id='btExcluir'
              size="small"
              color="primary"
              onClick={handleOpenExcluirDialog}
              disabled={!activeExcluir}>Excluir</Button>
            <Button
              id='btGravar'
              size="small"
              color="primary"
              onClick={handleClickGravar}
              disabled={disableButton}
              sx={{ mx: 2 }}>Gravar</Button>
            <Button
              id='btSair'
              size="small"
              color="primary"
              onClick={handleCloseCad}>Fechar</Button>
          </Grid>
        </DialogActions>
      </Dialog>
      <Dialog
        id='DialogExcluir'
        open={openExcluirDialog}
        onClose={() => { setOpenExcluirDialog(false) }}
        aria-describedby="alert-dialog-slide-description"
      >
        <DialogTitle>{"Excluir este registro?"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            Confirme a operação de EXCLUSÃO do registro, todos os dados do empreendimento {newPlace.empreendimento?.toUpperCase()} serão apagados.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClickExcluir}
          >Sim</Button>
          <Button
            onClick={() => { setOpenExcluirDialog(false) }}
          >Não</Button>
        </DialogActions>
      </Dialog>

    </Grid >

  )
}