import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { useTheme, Card, CardContent, Alert, AlertTitle } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { Copyright } from '../Copyright';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../firebase';


export default function Forget() {

  let navigate = useNavigate()

  const theme = useTheme();

  const [isError, setIsError] = useState(null)

  const [email, setEmail] = useState('')

  const handleSubmit = (event) => {

    event.preventDefault();

    const data = new FormData(event.currentTarget);

    let _email = data.get('email')

    if (email.length > 0) {
      sendPasswordResetEmail(auth, _email).then((token) => {
        setIsError('Email para renovação de senha enviado, caso esteja no cadastrado.')
      }).catch((e) => {
        setIsError('Email para renovação de senha enviado, caso esteja no cadastrado.')
      })
    }
    else { setIsError('Informe o email cadastrado.') }
    setEmail('')
  };

  function goToSingIn() {
    navigate('/singin', { replace: true })
  }

  function goToSingUp() {
    navigate('/singup', { replace: true })
  }
  return (

    <Grid container component="main" sx={{
      height: '100vh',
    }}>

      {/* <Grid item container
        xl={8}
        lg={8}
        md={12}
        sm={12}

        sx={{
          backgroundImage: 'url(./logo.svg)',
          backgroundRepeat: 'no-repeat',
          backgroundColor: theme.palette.primary.main,
          backgroundSize: 'contain',
          backgroundPosition: 'center',
        }}
      /> */}
      <Grid item
        xs={12}
        md={7}
        sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src="./gimov.svg" alt="Logo" style={{ width: '80%', height: 'auto' }} />
      </Grid>
      <Grid item
        xl={4}
        lg={4}
        md={12}
        sm={12}
        container
        alignContent='center'
      >
        <Grid
          item
          container
          justifyContent='center'
        >
          <Card sx={{
            width: '75%',
            [theme.breakpoints.down('md')]: {
              width: '90%',
            }
          }}>

            <CardContent>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                }}
              >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h4">
                  Esqueceu a senha?
                </Typography>
                <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: '30px' }}>
                  <Typography variant="body1">
                    Informe o email para envio da solicitação de renovação de senha:
                  </Typography>
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    label="Email"
                    name="email"
                    autoComplete="email"
                    autoFocus
                    value={email}
                    onChange={(e) => { setEmail(e.target.value) }}
                    inputProps={{ maxLength: 80 }}
                  />
                  <Button
                    id='button'
                    name='buton'
                    type="submit"
                    fullWidth
                    variant="contained"
                    sx={{ my: 2 }}
                  >
                    Solicitar nova senha
                  </Button>
                  <Grid container>
                    <Grid item xs>
                    <Link onClick={goToSingIn}>Já tem uma conta? Entre</Link>
                    </Grid>
                    <Grid item>
                    <Link onClick={goToSingUp}>Não tem conta? Cadastre-se</Link>
                    </Grid>
                  </Grid>
                  <Copyright sx={{ mt: 2 }} />
                </Box>
                {isError && (
                  <Box sx={{ mt: '10px', width: '100%' }}>
                    <Alert severity="info">
                      <Typography component={'span'} variant='body1'>
                        <AlertTitle>Informação</AlertTitle>
                        <strong>{isError}</strong>
                      </Typography>
                    </Alert>
                  </Box>
                )}
              </Box>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Grid>
  );
}