import { Alert, Button, CircularProgress, Grid, Snackbar, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { MyReminders } from '../../components/Crm/Leads/MyReminders';
import { MyTasks } from '../../components/Crm/Leads/MyTasks';
import dayjs from 'dayjs';
import 'dayjs/locale/pt-br.js';
import MyLeads from '../../components/Crm/Leads/MyLeads';
import { estatistica, getLeads, listLeadsDistribuidos } from '../../controllers/crm/leadController';
import { grey, red, yellow } from '@mui/material/colors';
import ParticiparEscala from '../../components/Crm/Escalas/ParticiparEscala';
import EscalasSelection from '../../components/Crm/Escalas/EscalasSelection';
import LeadsDistribuicao from '../../components/Crm/Leads/LeadsDistribuicao';
import LeadsControle from '../../components/Crm/Leads/LeadsControle';

import { rules } from '../../constants/rules';
import { leadStatus } from '../../constants/crm';
import { CampanhaSelect } from '../../components/Crm/Campanhas/CampanhaSelect';
import { plans } from '../../constants/plans';
import { listenToCredito } from '../../controllers/userController';
// import { onMessage } from 'firebase/messaging';
// import { messaging } from '../../firebase';




dayjs.locale('pt-br');

export function Home(props) {

  const [showMenu, setShowMenu] = useState(true)
  const [loading, setLoading] = useState(false)
  const [snackbar, setSnackbar] = useState(null)

  const [leadsList, setLeadsList] = useState([])
  const [filteredLeads, setFilteredLeads] = useState([])
  const [update, setUpdate] = useState(false)


  const [showReminders, setShowReminders] = useState(false)
  const [reminders, setReminders] = useState([]);

  const [showTasks, setShowTasks] = useState(false)
  const [tasks, setTasks] = useState([])
  const [dayInicio, setDayInicio] = useState(dayjs().startOf('week').toISOString())
  const [dayFim, setDayFim] = useState(dayjs().endOf('week').toISOString())

  const [showMyLeads, setShowMyLeads] = useState(false)
  const [dados, setDados] = useState(null)

  const [leadsAll, setLeadsAll] = useState([])

  const [showParticiparEscala, setShowParticiparEscala] = useState(false)
  const [showEscalasSelection, setShowEscalasSelection] = useState(false)
  const [showLeadsDistribuicao, setShowLeadsDistribuicao] = useState(false)
  const [showLeadsControl, setShowLeadsControl] = useState(false)
  const [showCampanhas, setShowCampanhas] = useState(false)

  const [ativarTmp, setAtivarTmp] = useState(false)
  const [temporizador, setTemporizador] = useState(null)
  // const [hasNewLead, setHasNewLead] = useState(false)


  async function loadMyLeads() {
    setLoading(true)
    const query = {
      select: {
        idlead: true,
        nome: true,
        email: true,
        telefone: true,
        faixaderenda: true,
        createdat: true,
        updatedat: true,
        idfase: true,
        idetapa: true,
        idoperador: true,
        isactive: true,
        status: true,
        interesse: true,
        dtdesignacao: true,
        version: true,
        origem: true,
        idcliente: true,
        fase: {
          select: {
            idfase: true,
            nome: true,
            ordem: true
          }
        },
        etapa: {
          select: {
            idetapa: true,
            nome: true,
            ordem: true
          }
        },
      },
      where: {
        idconta: props.account.conta,
        idoperador: props.account.id,
        isactive: true,
        status: { not: leadStatus.Disponivel }
      },
      orderBy: [{
        fase: {
          ordem: 'asc'
        },
      },
      {
        etapa: {
          ordem: 'asc'
        }
      },
      {
        createdat: 'asc'
      }
      ],
    }
    try {
      const api = await getLeads(query)
      if (api.status === 200) {
        // setUpdate(false)
        if (api.data.length > 0) {
          // console.log('MyLeads', api.data)
          setLeadsList(api.data)
          // setFilteredLeads(api.data)
          // setDados(estatistica(api.data))
        }
        else {
          setLeadsList([])
          setFilteredLeads([])
          setDados(null)
        }
      }
      else {
        setLeadsList([])
        setFilteredLeads([])
        setDados(null)
      }
    } catch (e) {
      // console.log('Error', e)
      setSnackbar({ message: 'Erro ao carregar Leads', severity: 'error' })
    }
    finally {
      setLoading(false)
    }

  }

  async function loadAllLeads() {
    setLoading(true)
    const dados = {
      idconta: props.account.conta,
      isactive: true,
    }
    try {
      const api = await listLeadsDistribuidos(dados)
      if (api.status === 200) {
        if (api.data.length > 0) {
          // console.log('All Leads', api.data)
          setLeadsAll(api.data)
        }
        else {
          setLeadsAll([])
        }
      }
      else {
        setLeadsAll([])
      }
    } catch (e) {

    }
    finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    loadMyLeads()
  }, [props.listen])

  // Update Leads
  useEffect(() => {
    if (update) {
      if (showLeadsDistribuicao) {
        loadAllLeads()
        loadMyLeads()
      }
      if (!showReminders) {
        // console.log('Clean Reminders', update)
        setReminders([])
      }
      if (!showTasks) {
        // console.log('Clean Tasks', update)
        setTasks([])
      }
      if (showMyLeads) {
        loadMyLeads()
      }
      if (!showLeadsControl) {
        loadAllLeads()
      }
      if (showLeadsControl) {
        loadMyLeads()
        loadAllLeads()
      }
      setUpdate(false)
    }
  }, [update])

  const handleCreditoUpdate = (result) => {
    if (result.success) {
      props.account.credito = result.credito
    } else {
      setSnackbar({ open: true, message: result.message, severity: 'error' });
    }
  };

  useEffect(() => {
    loadMyLeads()
    loadAllLeads()
    const subscription = listenToCredito(props.account, handleCreditoUpdate)
    return () => subscription();
  }, [])

  useEffect(() => {
    if (leadsList.length > 0) {
      setFilteredLeads(leadsList)
      setDados(estatistica(leadsList))
    }
  }, [leadsList])



  function handleExit() {
    if (showMenu) {
      // console.log('Desativar Temporizador AllLeads') 
      // clearInterval(tempLoadAllLeads)
      props.handleToHome()
    }
    if (showMyLeads) { setShowMyLeads(false); setShowMenu(true) }
    if (showReminders) { setShowReminders(false); setShowMenu(true) }
    if (showTasks) { setShowTasks(false); setShowMenu(true) }
    if (showParticiparEscala) { setShowParticiparEscala(false); setShowMenu(true) }
    if (showEscalasSelection) { setShowEscalasSelection(false); setShowMenu(true) }
    if (showLeadsDistribuicao) { setShowLeadsDistribuicao(false); setShowMenu(true) }
    if (showLeadsControl) {
      // console.log('Desativar Temporizador')
      clearInterval(temporizador)
      setAtivarTmp(false);
      setShowLeadsControl(false);
      setShowMenu(true)
    }
    if (showCampanhas) { setShowCampanhas(false); setShowMenu(true) }
  }

  return (
    <Grid
      container
      bgcolor={'secondary.main'}
    >
      {(loading) && ((
        <div style={{ position: 'fixed', top: '50vh', left: '50vw' }}>
          <CircularProgress />
        </div>
      ))}
      <Grid container
        height={{ xs: '82vh', md: '88vh' }}
        overflow={'auto'}
        py={1}
      >
        {showMenu && (
          <Grid container
            direction={'row'}
            justifyContent={'center'}
            alignContent={{ md: 'center' }}
            overflow={'auto'}
            p={2}
            gap={1}
          // height={'95%'}
          >
            {(props.account.rules.includes(rules.ruleAdm.title) && props.account.plano.toLowerCase() !== plans.planUser.title.toLowerCase()) &&
              <Grid container
                direction={'row'}
                justifyContent={'center'}
                alignContent={{ md: 'center' }}
                overflow={'auto'}
                gap={1}
              >
                {/* OnLine ADM */}
                <Grid item
                  height={{ xs: '100px', sm: '255px' }}
                  border={'1px solid black'}
                  width={{ xs: '350px', sm: '220px' }}
                  alignContent={'center'}
                  textAlign={'center'}
                  bgcolor={!loading ? 'primary.main' : grey[500]}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => { if (!loading) { setShowEscalasSelection(!showEscalasSelection); setShowMenu(!showMenu) } }}
                >
                  <Typography variant={'h1'} color={'white'}>OnLine Controle</Typography>
                </Grid>
                {/* Campanha ADM */}
                <Grid item
                  height={{ xs: '100px', sm: '255px' }}
                  border={'1px solid black'}
                  width={{ xs: '350px', sm: '220px' }}
                  alignContent={'center'}
                  textAlign={'center'}
                  bgcolor={!loading ? 'primary.main' : grey[500]}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => { if (!loading) { setShowCampanhas(!showCampanhas); setShowMenu(!showMenu) } }}
                >
                  <Typography variant={'h1'} color={'white'}>Leads Campanha</Typography>
                </Grid>
                {/* Distribuição de LEADS */}
                <Grid item
                  height={{ xs: '100px', sm: '255px' }}
                  border={'1px solid black'}
                  width={{ xs: '350px', sm: '220px' }}
                  alignContent={'center'}
                  textAlign={'center'}
                  bgcolor={!loading ? 'primary.main' : grey[500]}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => { if (!loading) { setShowLeadsDistribuicao(!showLeadsDistribuicao); setShowMenu(!showMenu) } }}
                >
                  <Typography variant={'h1'} color={'white'}>Leads Distribuição</Typography>
                </Grid>
                {/* Leads ADM */}
                <Grid item
                  height={{ xs: '100px', sm: '255px' }}
                  border={'1px solid black'}
                  width={{ xs: '350px', sm: '220px' }}
                  alignContent={'center'}
                  textAlign={'center'}
                  bgcolor={!loading ? 'primary.main' : grey[500]}
                  sx={{ cursor: 'pointer' }}
                  onClick={() => { if (!loading) { setShowLeadsControl(!showLeadsControl); setShowMenu(!showMenu) } }}
                >
                  <Typography variant={'h1'} color={'white'}>Leads Dashboard</Typography>
                </Grid>
              </Grid>
            }
            {dados && (
              <Grid item
                height={'255px'}
                bgcolor={'primary.main'}
                color={'white'}
                overflow={'clip'}
                width={{ xs: '350px', sm: '250px' }}
              >
                <Grid
                  container
                  direction={'column'}
                  justifyContent={'center'}
                  p={1}
                  gap={.5}
                >
                  <Typography variant="body1" component="div" fontSize={'12px'}>{`Total de Leads: ${dados.total}`}</Typography>
                  {dados && dados.map((p, i) => (
                    <div key={i} style={{
                      width: `${(p.total / dados.total * 100).toFixed(2)}%`,
                      // background: getColorFaseByOrdem(p.ordem)
                      background: red[300]
                    }}>
                      <Grid container
                        width={'250px'}
                        p={1}
                        direction={'row'}
                      >
                        <Typography variant="body1" component="div" fontSize={'12px'} pr={1}>
                          {`${p.fase}:`}
                        </Typography>
                        <Typography variant="body1" component="div" fontSize={'12px'} pr={.5} color={yellow[500]}>
                          {`${(p.total / dados.total * 100).toFixed(2)}% `}
                        </Typography>
                        <Typography variant="body1" component="div" fontSize={'12px'}>
                          {`(${p.total}) `}
                        </Typography>

                      </Grid>
                    </div>
                  ))
                  }
                </Grid>
              </Grid>
            )}
            <Grid item
              height={{ xs: '100px', sm: '255px' }}
              border={'1px solid black'}
              width={{ xs: '350px', sm: '220px' }}
              alignContent={'center'}
              textAlign={'center'}
              bgcolor={!loading ? 'primary.main' : grey[500]}
              sx={{ cursor: 'pointer' }}
              onClick={() => { if (!loading) { setShowMyLeads(!showMyLeads); setShowMenu(!showMenu) } }}
            >
              <Typography variant={'h1'} color={'white'}>Leads</Typography>
            </Grid>
            <Grid item
              height={{ xs: '100px', sm: '255px' }}
              border={'1px solid black'}
              width={{ xs: '350px', sm: '220px' }}
              alignContent={'center'}
              textAlign={'center'}
              bgcolor={!loading ? 'primary.main' : grey[500]}
              sx={{ cursor: 'pointer' }}
              onClick={() => { if (!loading) { setShowReminders(!showReminders); setShowMenu(!showMenu) } }}
            >
              <Typography variant={'h1'} color={'white'}>Lembretes</Typography>
            </Grid>
            <Grid item
              height={{ xs: '100px', sm: '255px' }}
              border={'1px solid black'}
              width={{ xs: '350px', sm: '220px' }}
              alignContent={'center'}
              textAlign={'center'}
              bgcolor={!loading ? 'primary.main' : grey[500]}
              sx={{ cursor: 'pointer' }}
              onClick={() => { if (!loading) { setShowTasks(!showTasks); setShowMenu(!showMenu) } }}
            >
              <Typography variant={'h1'} color={'white'}>Tarefas</Typography>
            </Grid>
            <Grid item
              height={{ xs: '100px', sm: '255px' }}
              border={'1px solid black'}
              width={{ xs: '350px', sm: '220px' }}
              alignContent={'center'}
              textAlign={'center'}
              bgcolor={!loading ? 'primary.main' : grey[500]}
              sx={{ cursor: 'pointer' }}
              onClick={() => { if (!loading) { setShowParticiparEscala(!showParticiparEscala); setShowMenu(!showMenu) } }}
            >
              <Typography variant={'h1'} color={'white'}>OnLine</Typography>
            </Grid>
          </Grid>
        )}
        {showReminders && (
          <MyReminders
            account={props.account}
            setSnackbar={setSnackbar}
            setShowReminders={setShowReminders}
            reminders={reminders}
            setReminders={setReminders}
            setUpdate={setUpdate}
            update={update}
            setShowMenu={setShowMenu}
          />
        )}
        {showTasks && (
          <MyTasks
            account={props.account}
            setSnackbar={setSnackbar}
            setShowTasks={setShowTasks}
            tasks={tasks}
            setTasks={setTasks}
            setUpdate={setUpdate}
            update={update}
            setShowMenu={setShowMenu}
            dayInicio={dayInicio}
            dayFim={dayFim}
            setDayInicio={setDayInicio}
            setDayFim={setDayFim}
          />
        )}
        {showMyLeads && (
          <MyLeads
            account={props.account}
            leadsList={leadsList}
            setLeadsList={setLeadsList}
            filteredLeads={filteredLeads}
            setFilteredLeads={setFilteredLeads}
            setSnackbar={setSnackbar}
            setShowMyLeads={setShowMyLeads}
            showMyLeads={showMyLeads}
            setUpdate={setUpdate}
            update={update}
            setShowMenu={setShowMenu}
            loadMyLeads={loadMyLeads}
          />
        )}
        {showParticiparEscala && (
          <ParticiparEscala
            account={props.account}
            setShowMenu={setShowMenu}
            setShowParticiparEscala={setShowParticiparEscala}
            showParticiparEscala={showParticiparEscala}
            setSnackbar={setSnackbar}
            snackbar={snackbar}
            setUpdate={setUpdate}
            update={update}

          />
        )}
        {showEscalasSelection && (
          <EscalasSelection
            account={props.account}
            setShowMenu={setShowMenu}
            setShowEscalasSelection={setShowEscalasSelection}
          />
        )}
        {showLeadsDistribuicao && (
          <LeadsDistribuicao
            account={props.account}
            setShowMenu={setShowMenu}
            setShowLeadsDistribuicao={setShowLeadsDistribuicao}
            setUpdate={setUpdate}
          />
        )}
        {showLeadsControl && (
          <LeadsControle
            account={props.account}
            setShowMenu={setShowMenu}
            setLeadsAll={setLeadsAll}
            leadsAll={leadsAll}
            update={update}
            setUpdate={setUpdate}
            setAtivarTmp={setAtivarTmp}
            ativarTmp={ativarTmp}
            temporizador={temporizador}
            setTemporizador={setTemporizador}
            loadAllLeads={loadAllLeads}
          />
        )}
        {showCampanhas && (
          <CampanhaSelect
            user={props.account}
            setSnackbar={setSnackbar}
            setLoading={setLoading}
          />
        )}
        {!!snackbar && (
          <Snackbar open
            onClose={() => setSnackbar(null)}
            autoHideDuration={6000}
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
          >
            <Alert {...snackbar} onClose={() => setSnackbar(null)}>{snackbar.message}</Alert>
          </Snackbar>
        )
        }
      </Grid>
      <Grid container
        bgcolor={'primary.main'}
        justifyContent={'end'}
        alignItems={'center'}
        px={1}
        height={'6vh'}
      >
        <Button
          variant="contained"
          color="secondary"
          size='small'
          onClick={handleExit}
        >Sair</Button>

      </Grid>
    </Grid >
  )
}